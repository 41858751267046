import {ScheduleApi} from "../../../../api/entities/scheduleApi";
const api = new ScheduleApi();

export default {
    actions: {
        deleteScheduleVisit(context, id) {
            return new Promise( async (resolve, reject) => {
                try {
                    await api.axios.delete(api.url + '/visits/' + id);
                    resolve()
                } catch (e) {
                    reject(e)
                }
            })
        },
        /**
         * Get the whole list of visits for new schedule dialog by route, outlet, and project or all projects
         * @param routeId - route_id
         * @param projectId - project_id. To get visits by all project use project_id = -2
         * @param outletId outlet_id
         * @returns {Promise<unknown>}
         */
        getAllOutletVisits({commit}, {routeId, projectId, outletId}) {
            return new Promise(async (resolve, reject) => {
                try{
                    let res = await api.axios.get(api.url + `/visits?routeId=${routeId}&projectId=${projectId}&outletId=${outletId}`)
                    commit('setAllOutletVisits', res.data);
                    resolve(res.data)
                } catch (e) {
                    reject(e)
                }
            })
        }
    },
    mutations: {
        setAllOutletVisits(state, visits) {
            state.allOutletVisits = visits
        }
    },
    state: {
        allOutletVisits: []
    },
    getters: {
        allOutletVisits: state => state.allOutletVisits
    }
}
