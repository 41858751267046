export default {
    actions: {
        toggleTheme({commit}, value) {
            commit('toggleTheme', value)
        },
        setReturnPath({commit}, path) {
            if(path === '/forbidden') path = undefined
            commit('setReturnPath', path)
        }
    },
    mutations: {
        toggleTheme(state, value) {
            state.darkTheme = value
        },
        setReturnPath(state, path) {
            state.returnPath = path
        }
    },
    state: {
        darkTheme: false,
        returnPath: undefined,
    },
    getters: {
        darkTheme: state => state.darkTheme,
        returnPath: state => state.returnPath,
    }
}
