import groups from "./groups";

const Outlet = () => import(/* webpackChunkName: "outlets" */ "../views/outlets/outlet")
const Outlets = () => import(/* webpackChunkName: "outlets" */ "../views/outlets/outlets")
const OutletProjectProperties = () => import(/* webpackChunkName: "outlets" */ "../views/outlets/outlet-project-properties")
const OutletFeatures = () => import(/* webpackChunkName: "outlets" */ "../views/outlets/outlet-features")
const OutletAggregationLevel = () => import(/* webpackChunkName: "outlets" */ "../views/outlets/aggregation/aggregation-level")
const OutletAggregationLevelFeatures = () => import(/* webpackChunkName: "outlets" */ "../views/outlets/aggregation/aggregation-level-features")
const OutletAggregation = () => import(/* webpackChunkName: "outlets" */ "../views/outlets/aggregation/aggregation")
const MergeDuplicates = () => import(/* webpackChunkName: "outlets" */ "../views/outlets/merge-duplicates")

export default [{
    path: '/outlets/:id',
    component: Outlet,
    meta: {
        title: 'ТТ',
        group: groups.outlets,
        accessKey: 'ROUTE_OUTLETS'
    }
}, {
    path: '/outlets',
    component: Outlets,
    meta: {
        title: 'Торговые точки',
        sideBar: {
            label: 'Торговые точки',
        },
        group: groups.outlets,
        accessKey: 'ROUTE_OUTLETS'
    }
}, {
    path: '/project-properties/outlets',
    component: OutletProjectProperties,
    meta: {
        title: 'Свойства ТТ по проектам',
        sideBar: {
            label: 'Свойства по проектам',
        },
        group: groups.outlets,
        accessKey: 'ROUTE_OUTLETS_PROPS'
    }
}, {
    path: '/features/outlets',
    component: OutletFeatures,
    meta: {
        title: 'Фичи ТТ',
        sideBar: {
            label: 'Набор элементов',
        },
        group: groups.outlets,
        accessKey: 'ROUTE_OUTLETS_PROPS_FEATURES'
    }
}, {
    path: '/levels/outlets',
    component: OutletAggregationLevel,
    meta: {
        title: 'Уровни агрегаций',
        sideBar: {
            label: 'Уровни агрегации',
        },
        group: groups.outlets,
        accessKey: 'ROUTE_OUTLETS_AGG_LEVELS'
    }
}, {
    path: '/levels-features/outlets',
    component: OutletAggregationLevelFeatures,
    meta: {
        title: 'Элементы уровней',
        sideBar: {
            label: 'Элементы уровней',
        },
        group: groups.outlets,
        accessKey: 'ROUTE_OUTLETS_AGG_LEVELS_FEATURES'
    }
}, {
    path: '/aggregations/outlets',
    component: OutletAggregation,
    meta: {
        title: 'Агрегации ТТ',
        sideBar: {
            label: 'Агрегации',
        },
        group: groups.outlets,
        accessKey: 'ROUTE_OUTLETS_AGGS'
    }
}, {
    path: '/merge-duplicates',
    component: MergeDuplicates,
    meta: {
        title: 'Слив дублей',
        sideBar: {
            label: 'Слив дублей',
        },
        group: groups.outlets,
        accessKey: 'ROUTE_OUTLETS_MERGE_DUPLICATES'
    }
}]