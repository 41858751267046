import {ScheduleApi} from "../../../../api/entities/scheduleApi";
const api = new ScheduleApi();

export default {
    actions: {
        getAllScheduleFeatures({commit}, { page = 1, pagesize = 10, order = null}) {
            return new Promise( async (resolve, reject) => {
                try {
                    let url = `${api.url}/features?page=${page}&pagesize=${pagesize}`;
                    if (order) url += `&order=${order}`;

                    let res = await api.axios.get(url);
                    commit('setAllScheduleFeatures', res.data);
                    commit('setAllScheduleFeaturesTotalCount', Number(res.headers['x-total-count']));
                    resolve(res.data)
                } catch (e) {
                    reject(e)
                }
            })
        },
        saveScheduleFeature({commit}, feature) {
            return new Promise( async (resolve, reject) => {
                try {
                    let url = `${api.url}/features`;
                    let res = await api.axios.post(url, feature);
                    commit('saveScheduleFeature', res.data);
                    resolve(res.data)
                } catch (e) {
                    reject(e)
                }
            })
        },
        updateScheduleFeature({commit}, feature) {
            return new Promise( async (resolve, reject) => {
                try {
                    let url = `${api.url}/features/${feature.id}`;
                    let res = await api.axios.put(url, feature);
                    commit('updateScheduleFeature', res.data);
                    resolve(res.data)
                } catch (e) {
                    reject(e)
                }
            })
        },
        lockScheduleFeature({commit}, id) {
            return new Promise( async (resolve, reject) => {
                try {
                    let url = `${api.url}/features_lock/${id}`;
                    let res = await api.axios.put(url);
                    commit('updateScheduleFeature', res.data);
                    resolve(res.data)
                } catch (e) {
                    reject(e)
                }
            })
        },
        deleteScheduleFeature({commit}, id) {
            return new Promise( async (resolve, reject) => {
                try {
                    let url = `${api.url}/features/${id}`;
                    await api.axios.delete(url);
                    commit('deleteScheduleFeature', id);
                    resolve()
                } catch (e) {
                    reject(e)
                }
            })
        }
    },
    mutations: {
        setAllScheduleFeatures(state, features) {
            state.scheduleFeatures = features
        },
        setAllScheduleFeaturesTotalCount(state, count) {
            state.scheduleFeaturesTotalCount = count
        },
        saveScheduleFeature(state, feature) {
            state.scheduleFeatures.push(feature);
            state.scheduleFeaturesTotalCount++;
        },
        updateScheduleFeature(state, feature) {
            let index = state.scheduleFeatures.findIndex(p => p.id === feature.id);
            state.scheduleFeatures.splice(index, 1, feature);
        },
        deleteScheduleFeature(state, featureId) {
            let index = state.scheduleFeatures.findIndex(p => p.id === featureId);
            state.scheduleFeatures.splice(index, 1);
            state.scheduleFeaturesTotalCount--;
        }
    },
    state: {
        scheduleFeatures: [],
        scheduleFeaturesTotalCount: 0
    },
    getters: {
        scheduleFeatures: state => state.scheduleFeatures,
        scheduleFeaturesTotalCount: state => state.scheduleFeaturesTotalCount,
    }
}
