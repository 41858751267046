import {TasksApi} from "../../api/entities/questionnaires/tasksApi";

export class Entity {
    constructor() {
        this.attributes = [];
        this.controls = [];
        this.dependencies = [];
    }

    fromJson(json) {
        this.json = json;

        this.id = this.json.id;
        this.attributes.forEach(a => a.value = this.json[a.key]);
        this.properties = (this.json.properties !== undefined) ? this.json.properties: [];
        this.aggregations = (this.json.aggregations !== undefined) ? this.json.aggregations: null;
        return this
    }

    setEventListener() {
        return this;
    }

    setAttributeOptions(){
        let  dep_values, dep_keys, promise;
        let that = this;
        if(Object.keys(this.dependencies).length > 0){
            dep_values = Object.values(this.dependencies);
            dep_keys = Object.keys(this.dependencies);
            promise = Promise.all(
                dep_values.map(item => {
                    if(item.Name === "ProjectsApi" && this.Name === "Questionnaire") {
                        return new TasksApi().getProjectsWithZeroProject()
                    } else if (item.Name == "ProjectsApi"){
                        return new item().getAll({pagesize: Number.MAX_SAFE_INTEGER, page: 1, status: "active"})
                    } else {
                        return new item().getAll({pagesize: Number.MAX_SAFE_INTEGER, page: 1})
                    }
                })
            ).then(res => {
                let result = [];
                for(let i in dep_keys){
                    result[dep_keys[i]] = res[i].data;
                    this.attributes.find(x => x.key === dep_keys[i]).options = res[i].data
                }
                return that;
            });
            return promise;
        } else {
            return new Promise(resolve => {return  resolve (that)});
        }
    }

    static get ColumnSettings() {
        return {
            align: 'center',
            sortable: false,
            filterable: false,
            divider: false,
            class: undefined,
            width: null,
            filter: null,
            sort: null
        }
    }
    static get Dependencies(){
        let tables = {};
        return tables;
    }


    get Name() {
        return this.name
    }
    static get Name(){
        return this.name;
    }
}
