<template>
    <v-checkbox
            v-model="control.value"
            :label="control.settings.label"
            :true-value="control.settings.trueValue"
            :false-value="control.settings.falseValue"
            :disabled="control.settings.disabled"
            :rules="control.validation.rules"
            @change="control.settings.func($event, control)"
    >
    </v-checkbox>
</template>

<script>
    export default {
        name: "df-v-checkbox",
        props: {
            control: Object
        }
    }
</script>
