import groups from "./groups";

const ImportReport = () => import(/* webpackChunkName: "import" */ "../views/reports/imports")
const ScheduleImport = () => import(/* webpackChunkName: "import" */ "../views/import/schedule")
const ImportMatrix = () => import(/* webpackChunkName: "import" */ "../views/imports/import_matrix")
const ImportQuestionnaire = () => import(/* webpackChunkName: "import" */ "../views/imports/import_questionnaire")
const ImportChecker = () => import(/* webpackChunkName: "import" */ "../views/imports/import_checker")
const ImportOutlets = () => import(/* webpackChunkName: "import" */ "../views/imports/import-outlets")
const ImportUsers = () => import(/* webpackChunkName: "import" */ "../views/imports/import-users")
const ImportPhotos = () => import(/* webpackChunkName: "import" */ "../views/imports/import_photos")
const ImportSku = () => import(/* webpackChunkName: "import" */ "../views/imports/import-sku")
const ImportMaterials = () => import(/* webpackChunkName: "import" */ "../views/imports/import-materials")
const ImportOutletsAggregationAndProperties = () => import(/* webpackChunkName: "import" */ "../views/imports/import-outlets-aggregation-and-properties")
const ImportRoutes = () => import(/* webpackChunkName: "import" */ "../views/imports/import-routes")
const ImportFileAndroid = () => import("../views/imports/import-fileAndroid")
const ImportQuestionnairesAggregation = () => import("../views/imports/import-questionnaires-aggregation")

export default [{
    path: '/import/outlets_aggregations_and_properties',
    component: ImportOutletsAggregationAndProperties,
    meta: {
        title: 'Загрузка агрегаций ТТ/свойств по проекту',
        sideBar: {
            label: 'Загрузка агрегаций ТТ/свойств по проекту',
        },
        group: groups.imports,
        accessKey: 'ROUTE_IMPORT_OUTLETS_AGGREGATIONS_AND_PROPERTIES'
    }
}, {
    path: '/import_schedule',
    component: ScheduleImport,
    meta: {
        title: 'Загрузка расписания',
        sideBar: {
            label: 'Загрузка расписания',
        },
        group: groups.imports,
        accessKey: 'ROUTE_IMPORT_SCHEDULE'
    }
}, {
    path: '/import_matrix',
    component: ImportMatrix,
    meta: {
        title: 'Загрузка матриц',
        sideBar: {
            label: 'Загрузка матриц',
        },
        group: groups.imports,
        accessKey: 'ROUTE_IMPORT_MATRIX'
    }
}, {
    path: '/import_reports',
    component: ImportReport,
    meta: {
        title: 'Загрузка отчетов',
        sideBar: {
            label: 'Загрузка отчетов',
        },
        group: groups.imports,
        accessKey: 'ROUTE_IMPORT_REPORTS'
    }
}, {
    path: '/import/questionnaire',
    component: ImportQuestionnaire,
    meta: {
        title: 'Загрузка опросников',
        sideBar: {
            label: 'Загрузка опросников',
        },
        group: groups.imports,
        accessKey: 'ROUTE_IMPORT_QUESTIONNAIRES'
    }
},{
    path: '/import/questionnaires_aggregation',
    component: ImportQuestionnairesAggregation,
    meta: {
        title: 'Загрузка агрегаций для опросников',
        sideBar: {
            label: 'Загрузка агрегаций для опросников'
        },
        group: groups.imports,
    }
},
    {
    path: '/import_checker',
    component: ImportChecker,
    meta: {
        title: 'Проверка логинов и маршрутов',
        sideBar: {
            label: 'Проверка логинов и маршрутов',
        },
        group: groups.imports,
        accessKey: 'ROUTE_IMPORT_CHECKER'
    }
}, {
    path: '/import/outlets',
    component: ImportOutlets,
    meta: {
        title: 'Загрузка АП',
        sideBar: {
            label: 'Загрузка АП',
        },
        group: groups.imports,
        accessKey: 'ROUTE_IMPORT_OUTLETS'
    }
}, {
    path: '/import/users',
    component: ImportUsers,
    meta: {
        title: 'Загрузка пользователей',
        sideBar: {
            label: 'Загрузка пользователей',
        },
        group: groups.imports,
        accessKey: 'ROUTE_IMPORT_USERS'
    }
}, {
    path: '/import/photos',
    component: ImportPhotos,
    meta: {
        title: 'Загрузка фотографий',
        sideBar: {
            label: 'Загрузка фотографий',
        },
        group: groups.imports,
        accessKey: 'ROUTE_IMPORT_PHOTOS'
    }
}, {
    path: '/import/sku',
    component: ImportSku,
    meta: {
        title: 'Загрузка скю',
        sideBar: {
            label: 'Загрузка скю',
        },
        group: groups.imports,
        accessKey: 'ROUTE_IMPORT_SKU'
    }
}, {
    path: '/import_materials',
    component: ImportMaterials,
    meta: {
        title: 'Привязка пользователей к материалам',
        sideBar: {
            label: 'Привязка пользователей к материалам',
        },
        group: groups.imports,
        accessKey: 'ROUTE_IMPORT_MATERIALS'
    }
}, {
    path: '/import_routes',
    component: ImportRoutes,
    meta: {
        title: 'Загрузка маршрутов',
        sideBar: {
            label: 'Загрузка маршрутов',
        },
        group: groups.imports,
        accessKey: 'ROUTE_IMPORT_ROUTES'
    }
}, {
    path: '/import_fileAndroid',
    component: ImportFileAndroid,
    meta: {
        title: 'Загрузка файла Android',
        sideBar: {
            label: 'Загрузка файла Android',
        },
        group: groups.imports
    }
}]
