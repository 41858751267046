import groups from "./groups";

const Project = () => import(/* webpackChunkName: "projects" */ "../views/projects/project")
const Projects = () => import(/* webpackChunkName: "projects" */ "../views/projects/projects")
const ProjectFeatures = () => import(/* webpackChunkName: "projects" */ "../views/projects/project-features")
const ProjectAggregationLevel = () => import(/* webpackChunkName: "projects" */ "../views/projects/aggregation/project-aggregation-level")
const ProjectAggregationLevelFeatures = () => import(/* webpackChunkName: "projects" */ "../views/projects/aggregation/project-aggregation-level-features")
const ProjectAggregation = () => import(/* webpackChunkName: "projects" */ "../views/projects/aggregation/project-aggregation")
const Status = () => import(/* webpackChunkName: "projects" */ "../views/projects/status")
const ProjectZero = () => import(/* webpackChunkName: "projects" */ "../views/projects/project_zero")

export default [{
    path: '/projects/:id',
    component: Project,
    meta: {
        title: 'Проект',
        group: groups.projects,
        accessKey: 'ROUTE_PROJECTS'
    }
}, {
    path: '/projects',
    component: Projects,
    meta: {
        title: 'Проекты',
        sideBar: {
            label: 'Все проекты',
        },
        group: groups.projects,
        accessKey: 'ROUTE_PROJECTS'
    }
}, {
    path: '/features/projects',
    component: ProjectFeatures,
    meta: {
        title: 'Свойства проектов',
        sideBar: {
            label: 'Свойства проектов',
        },
        group: groups.projects,
        accessKey: 'ROUTE_PROJECTS_PROPS'
    }
}, {
    path: '/levels/projects',
    component: ProjectAggregationLevel,
    meta: {
        title: 'Уровни агрегаций',
        sideBar: {
            label: 'Уровни агрегации',
        },
        group: groups.projects,
        accessKey: 'ROUTE_PROJECTS_AGG_LEVELS'
    }
}, {
    path: '/levels-features/projects',
    component: ProjectAggregationLevelFeatures,
    meta: {
        title: 'Элементы уровней',
        sideBar: {
            label: 'Элементы уровней',
        },
        group: groups.projects,
        accessKey: 'ROUTE_PROJECTS_AGG_LEVELS_FEATURES'
    }
}, {
    path: '/aggregations/projects',
    component: ProjectAggregation,
    meta: {
        title: 'Агрегации проектов',
        sideBar: {
            label: 'Агрегации',
        },
        group: groups.projects,
        accessKey: 'ROUTE_PROJECTS_AGGS'
    }
}, {
    path: '/status/projects',
    component: Status,
    meta: {
        title: 'Статус',
        sideBar: {
            label: 'Статус',
        },
        group: groups.projects,
        accessKey: 'ROUTE_PROJECTS_STATUS'
    }
}, {
    path: '/project_zero/',
    component: ProjectZero,
    meta: {
        title: 'Проект',
        sideBar: {
            label: 'Настройки Совмещенного мерчандайзинга',
        },
        group: groups.projects,
        accessKey: 'ROUTE_PROJECT_ZERO_SETTINGS'
    }
}]