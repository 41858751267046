import groups from "./groups";

const ReportExport = () => import(/* webpackChunkName: "export" */ "../views/exports/reports-export")
const NewPhoto = () => import(/* webpackChunkName: "export" */ "../views/exports/new-photo")
const PlanFactExport = () => import(/* webpackChunkName: "export" */ "../views/exports/plan-fact-export")
const BayerExport = () => import(/* webpackChunkName: "export" */ "../views/exports/bayer-export")

export default [{
    path: '/report-export',
    component: ReportExport,
    meta: {
        title: 'Выгрузка опросников',
        sideBar: {
            label: 'Выгрузка опросников',
        },
        group: groups.exports,
        accessKey: 'ROUTE_EXPORT_QUESTIONNAIRES'
    }
}, {
    path: '/reports-photo',
    component: NewPhoto,
    meta: {
        title: 'Фото отчёт',
        sideBar: {
            label: 'Фото отчёт',
        },
        group: groups.exports,
        accessKey: 'ROUTE_EXPORT_REPORTS_PHOTO'
    }
}, {
    path: '/plan-fact-export',
    component: PlanFactExport,
    meta: {
        title: 'Выгрузка План-Факт',
        sideBar: {
            label: 'Выгрузка План-факт',
        },
        group: groups.exports,
        accessKey: 'ROUTE_EXPORT_PLAN_FACT'
    }
}, {
    path: '/export/bayer',
    component: BayerExport,
    meta: {
        title: 'Выгрузка Bayer',
        sideBar: {
            label: 'Выгрузка Bayer',
        },
        group: groups.exports,
        accessKey: 'ROUTE_EXPORT_BAYER'
    }
}]