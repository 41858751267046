<template>
  <div class="forbidden">
    <v-card flat>
      <v-card-title>
        {{message}}
      </v-card-title>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" text link :to="returnPath">
          <v-icon left>mdi-chevron-left</v-icon>
          Вернуться
        </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "forbidden",
  computed: mapGetters(["returnPath"]),
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      message: this.query.message ?? 'У вас недостаточно прав для просмотра этой страницы',
    }
  },
  methods: {
    ...mapActions(["setReturnPath"]),
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.setReturnPath(from.path)
    })
  },
  beforeRouteLeave(to, from, next) {
    this.setReturnPath(undefined)
    next()
  }
}
</script>

<style scoped lang="css">
.forbidden {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.forbidden__message {
  text-align: center;
  max-width: 550px;
}

</style>
