import {ScheduleApi} from "../../../../api/entities/scheduleApi";
const api = new ScheduleApi();

export default {
    actions: {
        getAllScheduleProjectProps({commit}, { projectId = 0, page = 1, pageSize = 20, order = null}) {
            return new Promise( async (resolve, reject) => {
                try {
                    let url = `${api.url}/properties?projectId=${projectId}&page=${page}&pagesize=${pageSize}`;
                    if (order) url += `&order=${order}`;

                    let res = await api.axios.get(url);
                    commit('setAllScheduleProjectProps', res.data);
                    commit('setAllScheduleProjectPropsTotalCount', Number(res.headers['x-total-count']));
                    resolve(res.data)
                } catch (e) {
                    reject(e)
                }
            })
        },
        saveScheduleProjectProp({commit}, projectProp) {
            return new Promise( async (resolve, reject) => {
                try {
                    let url = `${api.url}/properties`;
                    let res = await api.axios.post(url, projectProp);
                    commit('saveScheduleProjectProp', res.data);
                    resolve(res.data)
                } catch (e) {
                    reject(e)
                }
            })
        },
        updateScheduleProjectProp({commit}, projectProp) {
            return new Promise( async (resolve, reject) => {
                try {
                    let url = `${api.url}/properties/${projectProp.id}`;
                    let res = await api.axios.put(url, projectProp);
                    commit('updateScheduleProjectProp', res.data);
                    resolve(res.data)
                } catch (e) {
                    reject(e)
                }
            })
        },
        deleteScheduleProjectProp({commit}, id) {
            return new Promise( async (resolve, reject) => {
                try {
                    let url = `${api.url}/properties/${id}`;
                    await api.axios.delete(url);
                    commit('deleteScheduleProjectProp', id);
                    resolve()
                } catch (e) {
                    reject(e)
                }
            })
        }
    },
    mutations: {
        setAllScheduleProjectProps(state, properties) {
            state.scheduleProjectProperties = properties
        },
        setAllScheduleProjectPropsTotalCount(state, count) {
            state.scheduleProjectPropertiesTotalCount = count
        },
        saveScheduleProjectProp(state, property) {
            state.scheduleProjectProperties.push(property);
            state.scheduleProjectPropertiesTotalCount++
        },
        updateScheduleProjectProp(state, property) {
            let index = state.scheduleProjectProperties.findIndex(p => p.id === property.id);
            state.scheduleProjectProperties.splice(index, 1, property);
        },
        deleteScheduleProjectProp(state, propertyId) {
            let index = state.scheduleProjectProperties.findIndex(p => p.id === propertyId);
            state.scheduleProjectProperties.splice(index, 1);
            state.scheduleProjectPropertiesTotalCount--
        }
    },
    state: {
        scheduleProjectProperties: [],
        scheduleProjectPropertiesTotalCount: 0,
    },
    getters: {
        scheduleProjectProperties: state => state.scheduleProjectProperties,
        scheduleProjectPropertiesTotalCount: state => state.scheduleProjectPropertiesTotalCount,
    }
}
