import {BaseApi} from "../baseApi";
import {ServicePorts} from "../../models/Enums";

export class UsersApi extends BaseApi {
	constructor() {
		super(ServicePorts.users)
	}

	loginAsync(user) {
		const data = {...user, password: btoa(user.password)}
		return this.axios.post(this.url + '/login', data)
	}
	getAll(params) {
		return this.axios.get(`${this.url}/users`, {params})
	}

	getUser() {
		return this.axios.get(`${this.url}/getUsers`)
	}

	changePassword(formData) {
		return this.axios.post(this.url + '/change_password', formData)
	}

	resetPassword(userId) {
		return this.axios.post(this.url + `/reset_password/${userId}`)
	}

	logoutAsync() {
		return this.axios.get(this.url + '/logout')
	}

	checkLogin(login) {
		return this.axios.post(this.url + '/check_login', {login: login})
	}

	disconnect(aggregation_id) {
		return this.axios.delete(this.url + '/aggregation/' + aggregation_id + '/connection');
	}

	getAllLight({page = 1, pagesize = 10}){
		let url = `${this.url}/all_lightweight?page=${page}&pagesize=${pagesize}`;
		return this.axios.get(url)
	}

	getStraightSubordinates(userId, params = null){
		let url = `${this.url}/straight_subordinates/${userId}`;
		return this.axios.get(url, { params })
	}

	getAllSvsAssistants(params = null){
		let url = `${this.url}/all_svs_assistants`;
		return this.axios.get(url, { params })
	}

	getAllSvs(params = null){
		let url = `${this.url}/all_svs`;
		return this.axios.get(url, { params })
	}
}
