import {BaseApi} from "../baseApi";
import {ServicePorts} from "../../models/Enums";

export class QuestionnairesApi extends BaseApi{
    constructor() {
        super(ServicePorts.questionnaire)
    }

    add(object) {
        delete object.properties;

        if(!object.training)
            object = this.processObject(object);

        return this.axios.post(`${this.url}/add`, object)
    }

    update(id, updatedObject) {
        delete updatedObject.properties;

        if(!updatedObject.training)
            updatedObject = this.processObject(updatedObject);

        return this.axios.put(`${this.url}/update/${id}`, updatedObject)
    }

    getOrderable(projectId) {
        return this.axios.get(`${this.url}/get_orderable/${projectId}`)
    }

    setOrder(id, questionnaires) {
        return this.axios.post(`${this.url}/set_order/${id}`, questionnaires)
    }
    getAllQuestionnaire(params){
        return this.axios.get(`${this.url}/all`, {params})
    }
    updateOrder(id, updatedObject) {
        delete updatedObject.properties;

        return this.axios.put(`${this.url}/update_order/${id}`, updatedObject)
    }

    addImage(image) {
        return this.axios.post(`${this.url}/questionnaire_image`, image);
    }
    updateQuestionnereAggregations(id, aggregations) {
        return this.axios.put(`${this.url}/updates/${id}`, aggregations)
    }

    deleteImage(imageId) {
        return this.axios.delete(`${this.url}/questionnaire_image/${imageId}`);
    }

    /**
     * Проверяет поле training и удаляет поля time_limit, at_once, number_of_tries, если training === false
     * @param object Объект
     * @returns {*} Объект без свойств, предназначенных только для обучения
     */
    processObject(object) {
        let processedObject = Object.assign({}, object);

        delete processedObject.time_limit;
        delete processedObject.at_once;
        delete processedObject.number_of_tries;

        return processedObject
    }
}
