import groups from "./groups";

const Sku = () => import(/* webpackChunkName: "sku" */ "../views/sku/sku")
const SkuList = () => import(/* webpackChunkName: "sku" */ "../views/sku/sku-list")
const SkuProjectProperties = () => import(/* webpackChunkName: "sku" */ "../views/sku/sku-project-properties")
const SkuFeature = () => import(/* webpackChunkName: "sku" */ "../views/sku/sku-features")
const SkuAggregationLevel = () => import(/* webpackChunkName: "sku" */ "../views/sku/aggregation/sku-aggregation-level")
const SkuAggregationLevelFeatures = () => import(/* webpackChunkName: "sku" */ "../views/sku/aggregation/sku-aggregation-level-features")
const SkuAggregation = () => import(/* webpackChunkName: "sku" */ "../views/sku/aggregation/sku-aggregation")
const Matrices = () => import(/* webpackChunkName: "sku" */ "../views/sku/matrices")
const Matrix = () => import(/* webpackChunkName: "sku" */ "../views/sku/matrix")

export default [{
    path: '/sku/:id',
    component: Sku,
    meta: {
        title: 'SKU',
        group: groups.sku,
        accessKey: 'ROUTE_SKU'
    }
}, {
    path: '/sku',
    component: SkuList,
    meta: {
        title: 'Список SKU',
        sideBar: {
            label: 'Все SKU',
        },
        group: groups.sku,
        accessKey: 'ROUTE_SKU'
    }
}, {
    path: '/project-properties/sku',
    component: SkuProjectProperties,
    meta: {
        title: 'Свойства SKU по проекту',
        sideBar: {
            label: 'Свойства по проектам',
        },
        group: groups.sku,
        accessKey: 'ROUTE_SKU_PROPS'
    }
}, {
    path: '/features/sku',
    component: SkuFeature,
    meta: {
        title: 'Набор элементов',
        sideBar: {
            label: 'Набор элементов',
        },
        group: groups.sku,
        accessKey: 'ROUTE_SKU_PROPS_FEATURES'
    }
}, {
    path: '/levels/sku',
    component: SkuAggregationLevel,
    meta: {
        title: 'Уровни агрегаций',
        sideBar: {
            label: 'Уровни агрегации',
        },
        group: groups.sku,
        accessKey: 'ROUTE_SKU_AGG_LEVELS'
    }
}, {
    path: '/levels-features/sku',
    component: SkuAggregationLevelFeatures,
    meta: {
        title: 'Уровни фич',
        sideBar: {
            label: 'Элементы уровней',
        },
        group: groups.sku,
        accessKey: 'ROUTE_SKU_AGG_LEVELS_FEATURES'
    }
}, {
    path: '/aggregations/sku',
    component: SkuAggregation,
    meta: {
        title: 'Агрегации SKU',
        sideBar: {
            label: 'Агрегации',
        },
        group: groups.sku,
        accessKey: 'ROUTE_SKU_AGGS'
    }
}, {
    path: '/matrices',
    component: Matrices,
    meta: {
        title: 'Матрицы',
        sideBar: {
            label: 'Матрицы',
        },
        group: groups.sku,
        accessKey: 'ROUTE_MATRICES'
    }
}, {
    path: '/matrices/:id',
    component: Matrix,
    meta: {
        title: 'Матрицы',
        group: groups.sku,
        accessKey: 'ROUTE_MATRICES'
    }
}]