import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import vuetify from './plugins/vuetify';
import AccessPlugin from './plugins/accesses';
import store from './store/index'
import Viewer from "v-viewer";
import 'viewerjs/dist/viewer.css'
import './styles/viewer-override.css'
import VVisibility from "./directives/visibility";
import {minutesToHhMmFilter, toRuDateStringFilter} from "./filters/filters";

Vue.use(require('vue-moment'));
Vue.use(Viewer)
Vue.use(AccessPlugin, {store})

Vue.filter('toRuDateString', toRuDateStringFilter);
Vue.filter('minutesToHhMm', minutesToHhMmFilter);

Vue.directive('visibility', VVisibility)

Vue.config.productionTip = false;

export const eventBus = new Vue()

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app');
