export default {
    actions: {

    },
    mutations: {

    },
    state: {
        map: {
            settings: {
                apiKey: '727c0596-27d4-4724-9300-5695139bc90d',
                lang: 'ru_RU',
                coordorder: 'latlong',
                version: '2.1'
            },
            controls: [
                'searchControl',
                'zoomControl',
                'geolocationControl',
                'typeSelector',
            ],
            coords: [55.755814, 37.617635],
            clusterOptions: {
                outletsCluster: {
                    preset: 'islands#redClusterIcons',
                },
            }
        },
        marker: {
            options: {
                preset: 'islands#redShoppingIcon',
                openEmptyBalloon: true
            }
        }
    },
    getters: {
        ymapSettings: state => state.map.settings,
        ymapControls: state => state.map.controls,
        ymapCoords: state => state.map.coords,
        ymapOptions: state => state.map.options,
        ymapClusterOptions: state => state.map.clusterOptions,
        ymarkerOptions: state => state.marker.options,
        routingYmapControls: () => [ 'fullscreenControl', 'zoomControl' ],
    }
}
