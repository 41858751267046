<template>
    <v-text-field
            v-model="control.value"
            @input="toNumber"
            type="number"
            :min="control.settings.min"
            :max="control.settings.max"
            :step="control.settings.step"
            :label="control.settings.label"
            :placeholder="control.settings.placeholder"
            :counter="control.settings.counter"
            :disabled="control.settings.disabled"
            :clearable="control.settings.clearable"
            :rules="control.validation.rules"
            :validate-on-blur="control.validation.validateOnBlur"
            @change="control.settings.func($event, control)"
    >
    </v-text-field>
</template>

<script>
    export default {
        name: "x-number-field",
        props: {
            control: Object
        },
        methods: {
            toNumber() {
                if(this.control.settings.clearable) {
                    this.control.value === null || this.control.value === ''
                        ? this.control.value = null
                        : this.control.value = Number(this.control.value)
                } else {
                    this.control.value = Number(this.control.value)
                }
            }
        }
    }
</script>




