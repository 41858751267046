import {ProjectsApi} from "../../api/entities/projectsApi";

const api = new ProjectsApi();

export default {
    actions: {
        getProjects({commit}) {
            return new Promise(async (resolve, reject) => {
                try {
                    let res = await api.getAll({pagesize: Number.MAX_SAFE_INTEGER, page: 1});
                    commit('setProjects', res.data);
                    resolve(res.data)
                } catch (e) {
                    reject(e)
                }
            })
        },
    },
    mutations: {
        setProjects(state, projects) {
            state.projects = projects;
        },
    },
    state: {
        projects: [],
    },
    getters: {
        projects: state => state.projects
    }
}
