import {AttribTypes} from "./Enums";

export class Attribute {
    constructor(key, add = true, edit = true, visible = true) {
        this.key = key;
        this.add = add;
        this.edit = edit;
        this.visible = visible;
    }

    SetValue(value) {
        this.value = value;
        return this;
    }

    AddRules({rules, validateOnBlur}){
        this.rules = rules;
        this.validateOnBlur = validateOnBlur;
        return this
    }
}

export class TextFieldAttribute extends Attribute{
    constructor(key, {add, edit, visible} = {add: true, edit: true, visible: true}) {
        super(key, add, edit, visible);
        this.type = AttribTypes.textField;
        this.value = '';
    }

    AddSettings({label, placeholder, counter, disabled, password, displayGenerator, displayEye}){
        this.label = label;
        this.placeholder = placeholder;
        this.counter = counter;
        this.disabled = disabled;
        this.password = password;
        this.displayGenerator = displayGenerator;
        this.displayEye = displayEye;
        return this
    }
}

export class NumberAttribute extends Attribute{
    constructor(key, {add, edit, visible} = {add: true, edit: true, visible: true}) {
        super(key, add, edit, visible);
        this.type = AttribTypes.number;
        this.value = 0;
    }

    AddSettings({label, placeholder, counter, disabled}) {
        this.label = label;
        this.placeholder = placeholder;
        this.counter = counter;
        this.disabled = disabled;
        return this;
    }
}

export class TextAreaAttribute extends Attribute{
    constructor(key, {add, edit, visible} = {add: true, edit: true, visible: true}) {
        super(key, add, edit, visible);
        this.type = AttribTypes.textArea;
        this.value = '';
    }

    AddSettings({label, placeholder, counter, disabled, autoGrow, rows}) {
        this.label = label;
        this.placeholder = placeholder;
        this.counter = counter;
        this.autoGrow = autoGrow;
        this.rows = rows;
        this.disabled = disabled;
        return this;
    }
}

export class SliderAttribute extends Attribute{
    constructor(key, {add, edit, visible} = {add: true, edit: true, visible: true}) {
        super(key, add, edit, visible);
        this.type = AttribTypes.slider;
        this.value = 0;
    }

    AddSettings({label, min, max, vertical, disabled}) {
        this.label = label;
        this.min = min;
        this.max = max;
        this.vertical = vertical;
        this.disabled = disabled;
        return this;
    }
}

export class SelectAttribute extends Attribute{
    constructor(key, {add, edit, visible} = {add: true, edit: true, visible: true}) {
        super(key, add, edit, visible);
        this.type = AttribTypes.select;
        this.value = null;
    }

    AddSettings({label, multiple, disabled}){
        this.label = label;
        this.multiple = multiple;
        this.disabled = disabled;
        return this
    }

    AddOptions({options, itemText, itemValue, returnObject}) {
        this.options = options;
        this.itemText = itemText;
        this.itemValue = itemValue;
        this.returnObject = returnObject;
        return this
    }
}

export class AutocompleteAttribute extends Attribute{
    constructor(key, {add, edit, visible} = {add: true, edit: true, visible: true}) {
        super(key, add, edit, visible);
        this.type = AttribTypes.autocomplete;
        this.value = null;
    }

    AddSettings({label, multiple, disabled}){
        this.label = label;
        this.multiple = multiple;
        this.disabled = disabled;
        return this
    }

    AddSource({promise, itemText, itemValue, returnObject}) {
        this.promise = promise;
        this.itemText = itemText;
        this.itemValue = itemValue;
        this.returnObject = returnObject;
        return this
    }
}

export class DateAttribute extends Attribute{
    constructor(key, {add, edit, visible} = {add: true, edit: true, visible: true}) {
        super(key, add, edit, visible);
        this.type = AttribTypes.date;
        this.value = new Date().toISOString();
    }

    AddSettings({label, firstDayOfWeek, locale, disabled }) {
        this.label = label;
        this.firstDayOfWeek = firstDayOfWeek;
        this.locale = locale;
        this.disabled = disabled;
        return this;
    }

    AddRules({min, max}){
        this.min = min;
        this.max = max;
        return this
    }
}

export class TimeAttribute extends Attribute{
    constructor(key, {add, edit, visible} = {add: true, edit: true, visible: true}) {
        super(key, add, edit, visible);
        this.type = AttribTypes.time;
        this.value = '00:00';
    }

    AddSettings({label, disabled }) {
        this.label = label;
        this.disabled = disabled;
        return this;
    }
}

export class CoordinatesAttribute extends Attribute{
    constructor(key, {add, edit, visible} = {add: true, edit: true, visible: true}) {
        super(key, add, edit, visible);
        this.type = AttribTypes.coordinates;
        this.value = [55.755814, 37.617635];
    }

    AddSettings({label, disabled}) {
        this.label = label;
        this.disabled = disabled;
        return this;
    }
}

export class CheckBoxAttribute extends Attribute{
    constructor(key, {add, edit, visible} = {add: true, edit: true, visible: true}) {
        super(key, add, edit, visible);
        this.type = AttribTypes.checkbox;
        this.value = false;
    }

    AddSettings({label, trueValue, falseValue, disabled}) {
        this.label = label;
        this.trueValue = trueValue;
        this.falseValue = falseValue;
        this.disabled = disabled;
        return this;
    }
}

export class ColorAttribute extends Attribute{
    constructor(key, {add, edit, visible} = {add: true, edit: true, visible: true}) {
        super(key, add, edit, visible);
        this.type = AttribTypes.color;
        this.value = '#0000FF';
    }

    AddSettings({label, disabled}) {
        this.label = label;
        this.disabled = disabled;
        return this;
    }
}

export class FileAttribute extends Attribute{
    constructor(key, {add, edit, visible} = {add: true, edit: true, visible: true}) {
        super(key, add, edit, visible);
        this.type = AttribTypes.file;
        this.value = [];
    }

    AddSettings({label, placeholder, counter, chips, multiple, accept, disabled}) {
        this.label = label;
        this.placeholder = placeholder;
        this.counter = counter;
        this.chips = chips;
        this.multiple = multiple;
        this.accept = accept;
        this.disabled = disabled;
        return this;
    }
}
