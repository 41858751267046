<template>
  <v-app>
    <v-app-bar app class="primary darken-2" dark>
      <v-app-bar-nav-icon v-if="tokenValid" @click="sideBar = !sideBar"/>

      <v-toolbar-title>
        <span>{{ hint }}</span>
      </v-toolbar-title>

      <v-spacer/>

      <v-toolbar-title>
        <span>{{ username }}</span>
      </v-toolbar-title>

      <v-btn v-if="isAuthenticated" icon @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="sideBar" width="auto" app temporary>
      <template #prepend>
        <v-expansion-panels flat tile style="border-bottom: 1px solid #cbc6c6">
          <v-expansion-panel>
            <v-expansion-panel-header hide-actions style="font-size: 18px;">
              Меню
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-row>
                  <v-spacer/>
                  <v-col cols="auto">
                    <v-switch v-model="theme" :prepend-icon="darkTheme ? 'mdi-weather-sunny' : 'mdi-weather-night'"></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <v-list dense>
        <v-list-group v-for="group in groups" :key="group.name" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon>{{ group.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ group.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-content>
          </template>
          <v-list-item v-for="route in group.routes"
                       :key="route.path" :to="route.path" dense>
            <v-list-item-title>
              {{ route.meta.sideBar.label }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>

    <change-password/>

    <v-snackbar v-model="infoSnackbar.model" :color="infoSnackbar.color" :timeout="infoSnackbar.timeout">{{infoSnackbar.text}}</v-snackbar>
  </v-app>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import ChangePassword from "./components/change-password";
import {eventBus} from "./main";
import {infoSnackbarMixin} from "./mixins/mixins";
import {API_ERROR, ERROR_MSG, SUCCESS_MSG} from "./models/Events";

export default {
  name: 'App',
  components: {ChangePassword},
  mixins: [infoSnackbarMixin],
  computed: {
    ...mapGetters({
      darkTheme: "darkTheme",
      username: "userName",
      userId: "userId",
      roleType: "roleType",
      tokenValid: "tokenValid",
      isAuthenticated: "isAuthenticated",
      accesses: "accesses"
    }),
    groups() {
      let routes = this.$router.options
          .routes.filter(r => !!r.meta?.sideBar)

      if (this.roleType !== 'admin')
        routes = routes.filter(r => this.accesses.map(a => a.key).includes(r.meta.accessKey))

      return [...new Set(routes.map(r => r.meta.group))]
          .map(g => ({...g, routes: routes.filter(r => r.meta.group === g)}))
    },
  },
  watch: {
    theme(enabled) {
      this.toggleTheme(enabled);
      this.$vuetify.theme.dark = this.darkTheme
    }
  },
  data() {
    return {
      hint: '',
      sideBar: false,
      theme: this.darkTheme,
    }
  },
  methods: {
    ...mapActions({
      toggleTheme: "toggleTheme",
      logoutAction: "logout",
      resetFilters: "resetAllFilters"
    }),
    logout() {
      this.logoutAction()
          .finally(() => {
            this.$router.push({name: 'login'})
          })
    }
  },
  mounted() {
    this.$vuetify.theme.dark = this.darkTheme;
    this.theme = this.darkTheme

    switch (process.env.VUE_APP_SFA_PORTS) {
      case 'DEVELOPMENT':
        this.hint = 'Локальный'
        break
      case 'STAGING':
        this.hint = 'Тестовый сервер'
        break
    }
  },
  created() {
    eventBus.$on(API_ERROR, e => this.showInfoSnackbar(e.response?.data, {color: 'red'}))
    eventBus.$on(SUCCESS_MSG, msg => this.showInfoSnackbar(msg, {color: 'green'}))
    eventBus.$on(ERROR_MSG, msg => this.showInfoSnackbar(msg, {color: 'red'}))
  }
};
</script>
