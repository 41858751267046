<template>
  <div>
    <v-dialog v-model="dialogModel" overlay-opacity=".97" persistent max-width="600">
      <v-card>
        <v-card-title class="mb-3">Для продолжения работы смените пароль</v-card-title>
        <v-card-subtitle class="pb-0">
          Пароль должен содержать не менее одной цифры, одной большой и одной маленькой английской буквы
          <br>Пароль не должен содержать пробелы
          <br>Минимальная длина пароля 6 символов
          <br>Пример пароля: 12AaBb
        </v-card-subtitle>
        <v-form ref="form" v-model="form.valid" @submit.prevent="submit">
          <v-card-text>
            <v-text-field id="change_password__password"
                          v-model="form.password"
                          autocomplete="off"
                          type="password"
                          label="Новый пароль"
                          name="password"
                          :rules="rules.password"
                          :append-icon="eyes.password"
                          validate-on-blur
                          @blur="$refs['form'].validate()"
                          @click:append="appendClicked('password')"
            />
            <v-text-field id="change_password__confirm"
                          v-model="form.confirm"
                          type="password"
                          label="Повторите пароль"
                          name="confirm"
                          :rules="rules.confirm"
                          :append-icon="eyes.confirm"
                          @click:append="appendClicked('confirm')"
            />
          </v-card-text>
          <v-card-actions class="pl-4">
            <v-btn text color="purple" @click="generatePassword">
              Сгенерировать
              <v-icon class="ml-2 mb-1" color="purple">mdi-auto-fix</v-icon>
            </v-btn>
            <v-spacer/>
            <v-btn text color="secondary" :loading="loading.exit" @click="onLogout">Выйти</v-btn>
            <v-btn type="submit" text color="primary" :disabled="!form.valid" :loading="loading.changing">Сохранить пароль</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="infoSnackbar.model" :color="infoSnackbar.color" :timeout="infoSnackbar.timeout">{{infoSnackbar.text}}</v-snackbar>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {infoSnackbarMixin} from "../mixins/mixins";
import {generatePassword} from "../Utils/Utils";
import Rules from "./common/dynamic-form/rules";

export default {
  name: "change-password",
  mixins: [infoSnackbarMixin],
  computed: {
    ...mapGetters(["strongPassword", "isAuthenticated"]),
    dialogModel() {
      return this.isAuthenticated && !this.strongPassword && this.$route.path !== '/login'
    }
  },
  data() {
    return {
      loading: {
        exit: false,
        changing: false
      },
      form: {
        valid: false,
        password: '',
        confirm: '',
      },
      rules: {
        password: Rules.Password(),
        confirm: [
            v => this.form.password.localeCompare(v) === 0 || "Пароли не совпадают"
        ]
      },
      eyes: {
        password: 'mdi-eye',
        confirm: 'mdi-eye'
      }
    }
  },
  methods: {
    ...mapActions(["logout", "changePassword"]),
    generatePassword() {
      new Promise(resolve => {
        const password = generatePassword()
        this.form.password = password
        this.form.confirm = password
        resolve()
      }).then(() => this.$refs['form'].validate())
    },
    onLogout() {
      this.loading.exit = true
      this.logout().finally(() => {
        this.loading.exit = false
        this.$router.push("/login")
        this.$refs['form'].resetValidation()
      })
    },
    submit(e) {
      this.loading.changing = true

      const fd = new FormData(e.target)
      fd.set("password", btoa(fd.get("password").toString()))
      fd.set("confirm", btoa(fd.get("confirm").toString()))

      this.changePassword(fd)
        .then(() => {
          this.showInfoSnackbar('Пароль успешно изменён', {color: 'green'})
        })
        .catch(e => {
          this.showInfoSnackbar(e.response.data, {color: 'red'})
        })
        .finally(() => {
          this.loading.changing = false
          this.form.password = ''
          this.form.confirm = ''
          this.eyes.password = 'mdi-eye'
          this.eyes.confirm = 'mdi-eye'
          this.$refs['form'].resetValidation()
        })
    },
    appendClicked(name) {
      const input = document.getElementById("change_password__" + name)
      if(input.type === 'text') {
        input.type = 'password'
        this.eyes[name] = 'mdi-eye'
      } else {
        input.type = 'text'
        this.eyes[name] = 'mdi-eye-remove'
      }
    }
  }
}
</script>

<style scoped>

</style>