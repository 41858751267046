import groups from './groups'

const Report = () => import(/* webpackChunkName: "reports" */ "../views/reports/report")
const Reports = () => import(/* webpackChunkName: "reports" */ "../views/reports/reports")
const Visit = () => import(/* webpackChunkName: "reports" */ "../views/visits/visit")
const Visits = () => import(/* webpackChunkName: "reports" */ "../views/visits/visits")
const Training = () => import(/* webpackChunkName: "reports" */ "../views/training-reports/training-report")
const Trainings = () => import(/* webpackChunkName: "reports" */ "../views/training-reports/training-reports")
const BiReports = () => import(/* webpackChunkName: "reports" */ "../views/reports/bi_reports")
const SettingBiReports = () => import(/* webpackChunkName: "reports" */ "../views/reports/settings_bi_reports")
const SettingBiReport = () => import(/* webpackChunkName: "reports" */ "../views/reports/settings_bi_report")
const CreateReport = () => import(/* webpackChunkName: "reports" */ "../views/reports/creating/create-report")

export default [{
    path: '/reports',
    component: Reports,
    meta: {
        title: 'Отчеты',
        sideBar: {
            label: 'Список отчетов',
        },
        group: groups.reports,
        accessKey: 'ROUTE_REPORTS'
    }
}, {
    path: '/reports/:id',
    component: Report,
    meta: {
        title: 'Отчет',
        group: groups.reports,
        accessKey: 'ROUTE_REPORTS'
    }
}, {
    path: '/visits',
    component: Visits,
    meta: {
        title: 'Визиты',
        sideBar: {
            label: 'Список визитов',
        },
        group: groups.reports,
        accessKey: 'ROUTE_VISITS'
    }
}, {
    path: '/visits/:id',
    component: Visit,
    meta: {
        title: 'Визит',
        group: groups.reports,
        accessKey: 'ROUTE_VISITS'
    }
}, {
    path: '/trainings',
    component: Trainings,
    meta: {
        title: 'Тренинги',
        sideBar: {
            label: 'Отчёты по тренингу',
        },
        group: groups.reports,
        accessKey: 'ROUTE_TRAININGS'
    }
}, {
    path: '/training',
    component: Training,
    props: r => ({user_id: Number(r.query.user_id), questionnaire_id: Number(r.query.questionnaire_id)}),
    meta: {
        title: 'Тренинг',
        group: groups.reports,
        accessKey: 'ROUTE_TRAININGS'
    },
}, {
    path: '/by-reports',
    component: BiReports,
    meta: {
        title: 'Отчёты BI',
        sideBar: {
            label: 'Отчёты BI',
        },
        group: groups.reports,
        accessKey: 'ROUTE_BI_REPORTS'
    }
}, {
    path: '/setting-by-reports',
    component: SettingBiReports,
    meta: {
        title: 'Настройка BI отчетности',
        sideBar: {
            label: 'Настройка BI отчетности',
        },
        group: groups.reports,
        accessKey: 'ROUTE_BI_REPORTS_SETTINGS'
    }
}, {
    path: '/setting-by-reports/:id',
    component: SettingBiReport,
    meta: {
        title: 'Настройка BI отчета',
        label: 'Настройка BI отчета',
        group: groups.reports,
        accessKey: 'ROUTE_BI_REPORTS_SETTINGS'
    }
}, {
    path: '/create-report',
    component: CreateReport,
    meta: {
        title: 'Создание отчёта',
        sideBar: {
            label: 'Создание отчёта',
        },
        group: groups.reports,
        accessKey: 'ROUTE_CREATE_REPORT'
    }
}, {
    path: '/reports/temp/:id',
    component: Report,
    meta: {
        title: 'Отчет (временный)',
        temporary: true,
        group: groups.reports,
        accessKey: 'ROUTE_REPORTS_TEMP'
    }
}]