import groups from "./groups";

const Notifications = () => import(/* webpackChunkName: "push" */ "../views/notifications/notifications")
const Notification = () => import(/* webpackChunkName: "push" */ "../views/notifications/notification")
export default [{
    path: '/notifications',
    component: Notifications,
    meta: {
        title: 'Уведомления на телефоне',
        sideBar: {
            label: 'Уведомления на телефоне',
        },
        group: groups.notification,
        accessKey: 'ROUTE_notification'
    }
}, {
    path: '/notification/:id',
    component: Notification,
    meta: {
    title: 'Уведомление',
    group: groups.notification,
        accessKey: 'ROUTE_notification'
}}]