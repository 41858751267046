const controlsMixin = {
    methods: {
        removeControls(controls, ...keys) {
            keys.forEach(key => {
                const controlIdx = controls.findIndex(c => c.key === key);
                if(controlIdx > 0) controls.splice(controlIdx, 1);
            });
        },
    }
};

const infoSnackbarMixin = {
    // Нужно добавить этот компонент в шаблон, хз, как обойтись без этого
    // <v-snackbar v-model="infoSnackbar.model" :color="infoSnackbar.color" :timeout="infoSnackbar.timeout">{{infoSnackbar.text}}</v-snackbar>
    data() {
        return {
            infoSnackbar: {
                model: false,
                text: '',
                timeout: -1,
                color: ''
            }
        }
    },
    methods: {
        showInfoSnackbar(text, {timeout = 3000, color = 'green'} = {}) {
            this.infoSnackbar.text = text;
            this.infoSnackbar.timeout = timeout;
            this.infoSnackbar.color = color;
            this.infoSnackbar.model = true;
        }
    },
};

export {controlsMixin, infoSnackbarMixin}
