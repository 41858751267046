<template>
    <v-select
            v-model="control.value"
            :items="control.options"
            :item-text="control.options.itemText"
            :item-value="control.options.itemValue"
            :return-object="control.options.returnObject"
            :label="control.settings.label"
            :multiple="control.settings.multiple"
            :disabled="control.settings.disabled"
            :rules="control.validation.rules"
            :validate-on-blur="control.validation.validateOnBlur"
            :no-data-text="'Не элементов для выбора'"
            @change="control.settings.func($event, control)"
    >
    </v-select>
</template>

<script>
    export default {
        name: "df-v-select",
        props: {
            control: Object
        }
    }
</script>
