import {QuestionnairesApi} from "../questionnairesApi";

export class TasksApi extends QuestionnairesApi{
    constructor() {
        super();
        this.url = `${this.url}/task`
    }

    add(object) {
        return this.axios.post(`${this.url}/add`, object)
    }

    exportTasks({order = null, search = "",project = null,  params= {}}){
        let url = `${this.url}/export?`;
        if(order) url += `&order=${order}`;
        if(search) url += `&search=${search}`;
        if((project) || project === 0) url += `&project=${project}`;
        if(Object.keys(params).length > 0){
            for(let k in params){
                url += `&${k}=${params[k]}`;
            }
        }
        return this.axios.get(url)
    }

    getProjects() {
        return this.axios.get(`${this.url}/projects`)
    }

    getProjectsWithZeroProject() {
        return this.axios.get(`${this.url}/projects_with_zero_project`)
    }
}
