import {ScheduleApi} from "../../../../api/entities/scheduleApi";
const api = new ScheduleApi();

export default {
    actions: {
        addOutletToSchedule(context, scheduleOutlet) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.axios.post(`${api.url}/scheduleoutlets`, scheduleOutlet);
                    resolve()
                } catch (e) {
                    reject(e)
                }
            })
        },
    },
}
