const BEHAVIORS = {
    OR: 'OR',
    AND: 'AND'
}

const AccessPlugin = {
    install(Vue, {store}) {
        /*
        Проверяет доступы к действиям, для которых не установлена привязка к проектам
        Пример:
        v-if="$hasAccess({keys: ['ACCESS_KEY_1']})"
        Если указано несколько ключей, можно дополнительно назначить поведение для проверки - И или ИЛИ
         */
        Vue.prototype.$hasAccess = function({keys = [], behavior = BEHAVIORS.AND}){
            const {accesses, roleType} = store.getters

            if (roleType === 'admin')
                return true

            if (!Object.values(BEHAVIORS).includes(behavior))
                throw Error(`[$hasAccess]: invalid argument ${behavior}. Must be one of ${Object.values(BEHAVIORS)}`)

            switch (behavior) {
                // в доступах пользователя должен быть хотя бы один ключ, указанный в модификаторах
                case BEHAVIORS.OR:
                    return !!accesses.find(a => keys.includes(a.key))
                // в доступах пользователя должны быть все ключи, указанные в модификаторах
                case BEHAVIORS.AND:
                    return accesses.filter(a => keys.includes(a.key)).length === keys.length
            }
        }

        /*
        Проверяет доступы к действиям, для которых установлена привязка к проектам
        Пример:
        v-if="$hasAccess(projectId, {keys: ['ACCESS_KEY_1']})"
        Если указано несколько ключей, можно дополнительно назначить поведение для проверки - И или ИЛИ
        Если действие подразумевает привязку к проекту, но привязка не установлена, то действие распространяется на все проекты.
        Если для роли на действие проекты были установлены, а с какого-то пользователя все проекты сняты, значит у пользователя
        нет доступа к действию ни по одному проекту, то есть вообще (равносильно снятию разрешения)
         */
        Vue.prototype.$hasAccessProject = function(projectId, {keys = [], behavior = BEHAVIORS.AND}){
            const {roleType} = store.getters

            if (roleType === 'admin')
                return true

            if (!projectId)
                return false

            if (!Object.values(BEHAVIORS).includes(behavior))
                throw Error(`[$hasAccess]: invalid argument ${behavior}. Must be one of ${Object.values(BEHAVIORS)}`)

            const accesses = store.getters.accesses.filter(a => keys.includes(a.key))

            let hasAccess = false

            switch (behavior) {
                case BEHAVIORS.AND:
                    hasAccess = true

                    // если в доступах пользователя НЕ столько же ключей, сколько передано в директиву
                    // значит доступа нет
                    if(accesses.length !== Object.keys(keys).length) {
                        return false
                    }

                    /*
                    доступы, для которых не назначены проекты, действуют по всем проектам,
                    соответсвенно не проверяем, есть ли у пользователя доступ по переданному проекту.
                    Если в доступе с проектами отсутсвует переданный проект, значит доступ запрещён
                     */
                    for(let access of accesses) {
                        if(!access.projects)
                            continue

                        if(!access.projects.includes(projectId)) {
                            return false
                        }
                    }
                    break
                case BEHAVIORS.OR:
                    /*
                    типа если среди его доступов найдены ключи, переданные в директиву, и если
                    какая-либо из них не ограничивается на проекты (то есть по всем проектам),
                    то доступ даём
                    либо если есть ограничение про проектам, то переданный проект должен содержаться в
                    доступах пользователя к действия
                     */
                    for (let access of accesses) {
                        if(!access.projects || access.projects.includes(projectId)) {
                            return true
                        }
                    }
                    break
            }

            return hasAccess
        }
    }
}

export default AccessPlugin