<template>
    <v-autocomplete
            v-model="control.value"
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            :item-text="control.options.itemText"
            :item-value="control.options.itemValue"
            :return-object="control.options.returnObject"
            :label="control.settings.label"
            placeholder="Начните вводить текст"
            :multiple="control.settings.multiple"
            :disabled="control.settings.disabled"
            :chips="control.settings.chips"
            :rules="control.validation.rules"
            :validate-on-blur="control.validation.validateOnBlur"
            @change="control.settings.func($event, control)"
            hide-no-data
            hide-selected>
        <template v-if="control.settings.chips" v-slot:selection="data">
            <v-chip
                    :input-value="data.selected"
                    close
                    @click:close="remove(data.item)"
            >
                {{data.item[control.options.itemText]}}
            </v-chip>
        </template>
    </v-autocomplete>
</template>

<script>
    export default {
        name: "df-v-autocomplete",
        props: {
            control: Object
        },
        data: function() {
            return {
                descriptionLimit: 60,
                entries: [],
                isLoading: false,
                search: null
            }
        },
        computed: {
            items: function() {
                return this.entries.map(entry => {
                    const Description = entry.Description?.length > this.descriptionLimit
                        ? entry.Description.slice(0, this.descriptionLimit) + '...'
                        : entry.Description;

                    return Object.assign({}, entry, { Description })
                })
            },
        },
        watch: {
            search: function() {
                if (this.control.api && this.search !== null && this.search !== "") {
                    this.isLoading = true;
                    this.loadSearching()
                }
                if (this.items.length > 0) return;
                if (this.isLoading) return;
                this.isLoading = true;

                this.loadItems();
            }
        },
        mounted() {
            this.loadItems();
        },
        updated() {
            if (this.control.api === null) {
                this.loadItems();
            }
        },
        methods: {
            loadItems() {
                this.control.promise
                    .then(res => res.data)
                    .then(res => {
                        this.entries = res;
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => (this.isLoading = false));
            },
            loadSearching() {
                const search = encodeURIComponent(this.search)
                this.control.api.getAll({pagesize: 100, params: {"search": search}})
                .then(res => res.data)
                .then(res => {
                    this.entries = res
                }).catch(err => {
                    console.log(err)
                }).finally(() => (this.isLoading = false));
            },
            remove(item) {
                if (Array.isArray(this.control.value)){
                    const index = this.control.value.indexOf(item.id);
                    if (index >= 0) this.control.value.splice(index, 1);
                } else {
                    this.control.value = null;
                }
                this.loadItems()
            }
        },
    }
</script>
