<template>
    <v-menu
            v-model="show"
            :close-on-content-click="false"
            :return-value.sync="color"
            :nudge-right="40"
            transition="slide-y-transition"
            offset-y
            min-width="290px">
        <template v-slot:activator="{ on }" :disabled="control.settings.disabled">
            <v-row align="center">
                <v-col cols="auto">
                    <v-label v-if="control.settings.label">{{control.settings.label}}:</v-label>
                </v-col>
                <v-col>
                    <div class="color-example" v-on="on" :style="{backgroundColor: control.value}"></div>
                </v-col>
            </v-row>
        </template>
        <v-card>
            <v-card-text>
                <v-color-picker v-model="color"
                                @change="control.settings.func($event, control)"
                                flat hide-inputs hide-canvas
                                show-swatches
                                swatches-max-height="230px"/>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn text color="primary" @click="handleOkClick">OK</v-btn>
                <v-btn text color="secondary" @click="handleCancelClick">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        name: "df-v-date-picker",
        props: {
            control: Object
        },
        data: function () {
            return {
                show: false,
                color: this.control.value,
            }
        },
        methods: {
            handleOkClick: function () {
                this.control.value = this.color;
                this.show = false;
            },
            handleCancelClick: function () {
                this.show = false;
            }
        }
    }
</script>

<style scoped>
    .color-example {
        height: 30px;
        width: 30px;
        border-style: solid;
        border-width: .5px;
        border-radius: 50%;
        cursor: pointer;
        transition: background-color .5s;
    }
</style>
