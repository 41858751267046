import {BaseApi} from "../baseApi";
import {ServicePorts} from "../../models/Enums";

export class RolesApi extends BaseApi{
    constructor() {
        super(ServicePorts.users);
        this.url = `${this.url}/role`;
    }

    getAllGroupedByProject({page = 1, pagesize = 10, order = null, search = "",project = null,  params= {}}){
        let url = `${this.url}/all_grouped_by_project?page=${page}&pagesize=${pagesize}`;
        if(order) url += `&order=${order}`;
        if(search) url += `&search=${search}`;
        if(project) url += `&project=${project}`;
        if(Object.keys(params).length > 0){
            for(let k in params){
                url += `&${k}=${params[k]}`;
            }
        }
        return this.axios.get(url)
    }
    getTypeRoles(project = null,){
        let url = `${this.url}//type_roles?`;
        if(project) url += `&project=${project}`;
        return this.axios.get(url)
    }

    get(id) {
        return this.axios.get(`${this.url}/get/${id}`)
    }

    getPermissions(id, projectId){
        return this.axios.get(`${this.url}/get_permissions/${id}?project=${projectId}`)
    }

    updatePermissions(id, object){
        return this.axios.put(`${this.url}/update_permissions/${id}`, object)
    }

    getAllLight(params) {
        return this.axios.get(`${this.url}/all/light`, {params})
    }

}
