import groups from "./groups";

const Route = () => import(/* webpackChunkName: "routes" */ "../views/routes/route")
const Routes = () => import(/* webpackChunkName: "routes" */ "../views/routes/routes")
const RouteAggregationLevel = () => import(/* webpackChunkName: "routes" */ "../views/routes/aggregation/route-aggregation-level")
const RouteAggregationLevelFeature = () => import(/* webpackChunkName: "routes" */ "../views/routes/aggregation/route-aggregation-level-features")
const RouteAggregation = () => import(/* webpackChunkName: "routes" */ "../views/routes/aggregation/route-aggregation")
const Routing = () => import(/* webpackChunkName: "routes" */ "../views/routes/routing")
const HmPrice = () => import(/* webpackChunkName: "routes" */ "../views/routes/hm-price")
const RouteCreating = () => import(/* webpackChunkName: "routes" */ "../views/routes/route-creating")

export default [{
    path: '/routes/:id',
    component: Route,
    meta: {
        title: 'Маршрут',
        group: groups.routes,
        accessKey: 'ROUTE_ROUTES'
    }
}, {
    path: '/routes',
    component: Routes,
    meta: {
        title: 'Маршруты',
        sideBar: {
            label: 'Маршруты',
        },
        group: groups.routes,
        accessKey: 'ROUTE_ROUTES'
    }
}, {
    path: '/levels/routes',
    component: RouteAggregationLevel,
    meta: {
        title: 'Уровни агрегаций',
        sideBar: {
            label: 'Уровни агрегации',
        },
        group: groups.routes,
        accessKey: 'ROUTE_ROUTES_AGG_LEVELS'
    }
}, {
    path: '/levels-features/routes',
    component: RouteAggregationLevelFeature,
    meta: {
        title: 'Элементы уровней',
        sideBar: {
            label: 'Элементы уровней',
        },
        group: groups.routes,
        accessKey: 'ROUTE_ROUTES_AGG_LEVELS_FEATURES'
    }
}, {
    path: '/aggregations/routes',
    component: RouteAggregation,
    meta: {
        title: 'Агрегации маршрутов',
        sideBar: {
            label: 'Агрегации',
        },
        group: groups.routes,
        accessKey: 'ROUTE_ROUTES_AGGS'
    }
}, {
    path: '/routing',
    component: Routing,
    meta: {
        title: 'Маршрутизация',
        sideBar: {
            label: 'Маршрутизация',
        },
        group: groups.routes,
        accessKey: 'ROUTE_ROUTES_ROUTING'
    }
}, {
    path: '/hm',
    component: HmPrice,
    meta: {
        title: 'Гипермаркеты',
        sideBar: {
            label: 'Гипермаркеты',
        },
        group: groups.routes,
        accessKey: 'ROUTE_ROUTES_HM'
    }
}, {
    path: '/route-creating',
    component: RouteCreating,
    meta: {
        title: 'Создание маршрута',
        sideBar: {
            label: 'Создание маршрута',
        },
        group: groups.routes,
        accessKey: 'ROUTE_ROUTES_CREATE_ROUTE'
    }
}]