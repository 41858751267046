import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';

import Login from '../views/login';
import NotFound from "../views/not-found";
import Forbidden from "../views/forbidden";

import reports from './reports'
import projects from './projects'
import sku from './sku'
import users from './users'
import sfa_routes from './routes'
import outlets from './outlets'
import schedule from './schedule'
import questionnaires from './questionnaires'
import reasons from './reasons'
import materials from './materials'
import imports from './imports'
import notification from './notification'
import exports from './exports'
import admin from './admin'

Vue.use(VueRouter);

const routes = [{
    path: '/',
    name: 'root',
    redirect: '/login',
}, {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
        title: 'Вход',
    }
},
    ...reports,
    ...projects,
    ...sku,
    ...users,
    ...sfa_routes,
    ...outlets,
    ...schedule,
    ...questionnaires,
    ...reasons,
    ...materials,
    ...notification,
    ...imports,
    ...exports,
    ...admin,
{
    path: '/forbidden',
    name: 'forbidden',
    component: Forbidden,
    props: route => ({query: route.query}),
    meta: {
        title: 'Доступ ограничен'
    }
}, {
    path: '*',
    name: 'not-found',
    component: NotFound,
    meta: {
        title: 'Страница не найдена'
    }
}]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    const authenticated = store.getters.isAuthenticated && store.getters.tokenValid

    if (!authenticated && !['root', 'login'].includes(to.name)) {
        store.dispatch('setReturnPath', to.path)
        next({name: 'login'})
        return
    }

    const accessToRoute = store.getters.roleType === 'admin' ||
        ['root', 'login', 'forbidden', 'not-found'].includes(to.name) ||
        store.getters.accesses.map(a => a.key).includes(to.meta.accessKey)

    if(!accessToRoute){
        next({name: 'forbidden' })
        return
    }
    next()
});

router.afterEach((to) => {
    checkFilters()
    Vue.nextTick(() => document.title = to.meta.title ?? 'Страница без названия')
});

function checkFilters() {
    store.dispatch("setRoute").catch(e => alert(e))
}

export default router
