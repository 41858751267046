import {ScheduleApi} from "../../../../api/entities/scheduleApi";
const api = new ScheduleApi();

export default {
    actions: {
        updateSchedulePropertyValue(context, updated) {
            return new Promise( async (resolve, reject) => {
                try {
                    await api.axios.put(`${api.url}/propertyvalues/${updated.id}`, updated);
                    resolve()
                } catch (e) {
                    reject(e)
                }
            })
        }
    },
}
