<template>
  <v-menu
      v-model="show"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="slide-y-transition"
      offset-y
      min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
          :value="control.value | toShortString"
          :label="control.settings.label"
          :disabled="control.settings.disabled"
          readonly
          type="date"
          v-on="on">
      </v-text-field>
    </template>
    <v-date-picker v-model="ISOToShortDateString"
                   @input="handleDateInput"
                   :locale="control.settings.locale || 'ru'"
                   :first-day-of-week="control.settings.firstDayOfWeek || 1"
                   :min="control.validation.min"
                   :max="control.validation.max"
                   @change="control.settings.func($event, control)"
    />
  </v-menu>
</template>

<script>
export default {
  name: "df-v-date-picker",
  props: {
    control: Object
  },
  data: function () {
    return {
      show: false
    }
  },
  methods: {
    handleDateInput(date) {
      if(this.control.settings.format === 'date')
        this.control.value = date
      this.show = false
    }
  },
  computed: {
    ISOToShortDateString: {
      get: function () {
        return this.control.value.substring(0, 10)
      },
      set: function (value) {
        this.control.value = new Date(value).toISOString()
      }
    }
  },
  filters: {
    toShortString: function (isoDate) {
      return isoDate.substring(0, 10)
    }
  }
}
</script>
