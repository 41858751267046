
export default class Utils {
    static compare(a, b, attribute) {
        const position1  = a[attribute];
        const position2 = b[attribute];
        let comparison = 0;
        if (position2 < position1) {
            comparison = 1;
        } else if (position2 > position1) {
            comparison = -1;
        }
        return comparison;
    }

    static getNestedNodeById(a, n) { //a array of nodes, n - node id
        let nd = null;
        getNest(a, n);

        function getNest(arr, node) {
            for (const item of arr) {
                if (item.id === node) {
                    nd = item;
                    break;
                } else {
                    if (item['children'].length > 0) {
                        getNest(item['children'], node);
                    }
                }
            }

        }

        return nd;
    }

    static getSelectionNode(a) { //a array of nodes, n - node id
        let nd = null;
        getNest(a);

        function getNest(arr) {
            for (const item of arr) {
                if (item.selected === true) {
                    nd = item;
                    break;
                } else {
                    if (item['children'].length > 0) {
                        getNest(item['children']);
                    }
                }
            }

        }

        return nd;
    }

    static getlastNodes(item) { //a array of nodes,
        if(item === null){
            return [];
        }
        let nd = [];
        if (item['children'].length > 0) {
            getNest(item['children']);
        }else{
            nd.push(item);
        }

        function getNest(arr) {
            for (const item of arr) {
                if (item['children'].length > 0) {
                    getNest(item['children']);
                }else{
                    nd.push(item);
                }
            }

        }

        return nd;
    }

    static getDifference(a, b){
        let arr1, arr2;

        if (a.length < b.length) {
            arr2 = a;
            arr1 = b;
        } else {
            arr2 = b;
            arr1 = a;
        }

        let diff  = arr1.filter(x => -1 === arr2.findIndex((item) => item.id === x.id));
        return diff;
    }

    static setSelection(difference, arr) { //difference - ids [], nodes []
        getNest(difference, arr, false);

        function getNest(difference, arr, selectAll = false) {
            for (const item of arr) {
                if(selectAll) {
                    item['selected'] = true;
                } else {
                    item['selected'] = (difference.indexOf(item['id'])> -1) ? true : false;
                }
                if (item['children'].length > 0) {
                    getNest(difference, item['children'], item['selected']);
                }
            }
        }

        for(const d of difference){
            setParents(d);
        }

        function setParents(d){
            const parent = Utils.getParentNestedById(arr, {id: d});
            if(parent) {
                const indeterminate = parent.children.filter(x => x.indeterminate !== undefined && x.indeterminate === true );
                if(indeterminate > 0){
                    parent.selected = false;
                    parent.indeterminate = true;
                    setParents(parent['id'])
                }
                const checkChild = parent.children.filter(x => x.selected === false);
                if(checkChild < 0){
                    parent.selected = true;
                    parent.indeterminate = false;
                } else {
                    parent.selected = false;
                    parent.indeterminate = true;
                }
                if(parent.parent_id !== 0){
                    setParents(parent['id'])
                }
            }
        }

    }
    static getAllParents(arr, selected){
        let res = [{text: selected[0].name, last: true}];
        let id = selected[0].id;

        while (getParents(arr, id)){
           let parent = getParents(arr, id);
           res.push({text: parent.name, last: false});
           id = parent.id;
        }

        function getParents(arr, id){
            let parent = Utils.getParentNestedById(arr, {id: id});
            return  (parent) ? parent : false ;
        }
        return res.reverse();
    }


    static getParentNestedById(a, n) { // tree , node {id: }
        let nd = null;
        getPar(a, n);
        function getPar(arr, node) {
            for (const item of arr) {
                if (item['children'].length > 0) {
                    if (item['children'].find(x => x.id === node.id) !== undefined)  {
                        nd = item;
                        break;
                    } else {
                        if (item['children'] !== undefined && item['children'].length > 0) {
                            getPar(item['children'], node);
                        }
                    }
                }
            }
        }

        return nd;
    }

    static getAvailableFeatures(itemProps, features) {
        return features.filter(item => -1 === itemProps.findIndex((x) => {
            return x.agg_feature_id === item.id;
        }));
    }

    static capitalizeFirstLetter(string) {
        if(typeof string === 'string')
            return string.charAt(0).toUpperCase() + string.substring(1);
        throw 'Unable to apply string functions to '+ typeof string + ' value';
    }

    static log(message) {
        if(message.response && message.response.status === 403){
            return;
        }
        alert(message)
    }

    static resolve(path, obj) {
        return path.split('.').reduce(function(prev, curr) {
            return prev ? prev[curr] : null
        }, obj || self)
    }

    /**
     * Функция, использующаяся в компоненте base-aggregation, которая проставляет состояния (selected,
     * indeterminate, not selected) на агрегациях в дереве
     * @param arr ??
     * @param param ??
     * @param val ??
     * @param sel id агрегации, которая выбрана в группе
     * @param isSel ??
     * @param stopOnSelected говорит, останавливать ли проставлять метки selected на выбранной агрегации
     * @param stop переменная, которая останавливает рекурсивный вызов на выбранном элементе при stopOnSelected = true
     */
    static setParamForItem(arr, param, val, sel,  isSel = false, stopOnSelected = false, stop = false){
        if(arr === null || stop){
            return
        }
        arr.forEach( item => {
            if(isSel){
                item['indeterminate'] = false;
                item['selected'] = true;
            } else {
                item['indeterminate'] = val.indexOf(item.id) > -1;
                item['selected'] = item.id === sel;
                if(stopOnSelected)
                    stop = item.id === sel
            }
            if (item['children'] && item['children'].length > 0) {
                this.setParamForItem(item['children'], param, val, sel, item['selected'], stopOnSelected, stop);
            }
        })
    }

    static setParamForItemMulti(arr, val, sel, isSel = false){
        if(arr === null){
            return
        }
        arr.forEach( item => {
            if(isSel){
                item['indeterminate'] = false;
                item['selected'] = true;
            } else {
                item['indeterminate'] = val.indexOf(item.id) > -1 && sel.indexOf(item.id) === -1;
                item['selected'] = sel.indexOf(item.id) > -1;
            }
            if (item['children'] && item['children'].length > 0) {
                this.setParamForItemMulti(item['children'], val, sel, item['selected']);
            }
        })
    }

    static parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    static getDistanceFromLatLonInM(lat1,lon1,lat2,lon2) {
        let R = 6371; // Radius of the earth in km
        let dLat = Utils.deg2rad(lat2-lat1);
        let dLon = Utils.deg2rad(lon2-lon1);
        let a =
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(Utils.deg2rad(lat1)) * Math.cos(Utils.deg2rad(lat2)) *
            Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        return  R * c * 1000;
    }

    static deg2rad(deg) {
        return deg * (Math.PI/180)
    }

    static getMonday(d) {
        d = new Date(d);
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0 ,0));
        let day = d.getDay(),
            diff = d.getDate() - day + (day === 0 ? -6 : 1);
        const res = new Date(d.setDate(diff));
        return res;
    }

    static getSunday(d) {
        let monday = this.getMonday(d);
        return new Date(monday.setDate(monday.getDate() + 6));
    }

}

export const downloadCsv = (httpResult, fileName = 'export', addBom = false, charset = 'utf-16le') => {
    const blobParts = [httpResult.data]
    if (addBom) blobParts.unshift("\uFEFF")

    const url = window.URL.createObjectURL(new Blob(blobParts, {type: `text/csv; charset=${charset}`}));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
}

const getRandElement = (array) => array[Math.floor(Math.random() * array.length)]

export const generatePassword = ({minLength = 6, maxLength = 10} = {}) => {
    if(minLength < 6 || minLength > maxLength)
        throw Error('Пароль не должен быть меньше 6 символов или минимальная длина пароля больше максимальной')

    const lengths = []
    for(let i = minLength; i <= maxLength; i++) {
        lengths.push(i)
    }

    const length = getRandElement(lengths)

    const types = ['lower', 'upper', 'numbers', 'symbols']
    const charset = {
        [types[0]]: "abcdefghijklmnopqrstuvwxyz",
        [types[1]]: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        [types[2]]: "0123456789",
        [types[3]]: "!@#$%^&*()[]{}",
    }

    let password = ''

    for(let i = 0; i < length; i++) {
        let type = ''
        if (i === 1) {
            type = types[2]
        } else if (i === 3) {
            type = types[0]
        } else if (i === 5) {
            type = types[1]
        } else {
            type = getRandElement(types)
        }
        password += getRandElement(charset[type])
    }

    return password
}