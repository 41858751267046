<template>
    <v-text-field
            v-model="control.value"
            :label="control.settings.label"
            :placeholder="control.settings.placeholder"
            :counter="control.settings.counter"
            :disabled="control.settings.disabled"
            :clearable="control.settings.clearable"
            :type="control.settings.password ? 'password' : ''"
            :rules="control.validation.rules"
            :validate-on-blur="control.validation.validateOnBlur"
            @change="control.settings.func($event, control)"
            :class="getClass"
            :append-outer-icon="control.settings.append_icon"
            @click:append-outer="control.settings.append_click(control)"
    >
      <template v-slot:append>
        <v-btn v-if="control.settings.displayGenerator" icon @click="generatePassword">
          <v-icon color="purple">mdi-auto-fix</v-icon>
        </v-btn>
        <v-btn v-if="control.settings.displayEye" icon @click="control.settings.password = !control.settings.password">
          <v-icon color="primary">{{eyeIcon}}</v-icon>
        </v-btn>
      </template>
    </v-text-field>
</template>

<script>
    import {generatePassword} from "../../../../Utils/Utils";

    export default {
        name: "df-v-text-field",
        props: {
            control: Object
        },
        computed: {
            getClass() {
                if (this.control.settings.invisible === true) {
                    return 'd-none';
                }
                return ""
            },
          eyeIcon() {
              return this.control.settings.password ? 'mdi-eye' : 'mdi-eye-remove'
          }
        },
      methods: {
          generatePassword() {
            this.control.value = generatePassword()
          }
      }
    }
</script>




