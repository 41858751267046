import groups from "./groups";

const Questionnaire = () => import(/* webpackChunkName: "questionnaires" */ "../views/questionnaire/questionnaire")
const Tasks = () => import(/* webpackChunkName: "questionnaires" */ "../views/questionnaire/tasks")
const Questionnaires = () => import(/* webpackChunkName: "questionnaires" */ "../views/questionnaire/questionnaires")
const QuestionnaireProjectProperties = () => import(/* webpackChunkName: "questionnaires" */ "../views/questionnaire/questionnaire-project-properties")
const QuestionnaireFeatures = () => import(/* webpackChunkName: "questionnaires" */ "../views/questionnaire/questionnaire-features")
const QuestionnaireAggregationLevel = () => import(/* webpackChunkName: "questionnaires" */ "../views/questionnaire/aggregation/questionnaire-aggregation-level")
const QuestionnaireAggregationLevelFeatures = () => import(/* webpackChunkName: "questionnaires" */ "../views/questionnaire/aggregation/questionnaire-aggregation-level-features")
const QuestionnaireAggregation = () => import(/* webpackChunkName: "questionnaires" */ "../views/questionnaire/aggregation/questionnaire-aggregation")

export default [{
    path: '/tasks',
    component: Tasks,
    meta: {
        title: 'Поставленные задачи',
        sideBar: {
            label: 'Поставить задачу',
        },
        group: groups.questionnaires,
        accessKey: 'ROUTE_TASKS'
    }
},{
    path: '/tasks/:id',
    component: Questionnaire,
    meta: {
        title: 'Задача от руководителя',
        group: groups.questionnaires,
        accessKey: 'ROUTE_TASKS'
    }
},{
    path: '/questionnaires/:id',
    component: Questionnaire,
    meta: {
        title: 'Опросники',
        group: groups.questionnaires,
        accessKey: 'ROUTE_QUESTIONNAIRES'
    }
}, {
    path: '/questionnaires',
    component: Questionnaires,
    meta: {
        title: 'Опросники',
        sideBar: {
            label: 'Опросники',
        },
        group: groups.questionnaires,
        accessKey: 'ROUTE_QUESTIONNAIRES'
    }
}, {
    path: '/project-properties/questionnaires',
    component: QuestionnaireProjectProperties,
    meta: {
        title: 'Свойства опросников по проекту',
        sideBar: {
            label: 'Свойства по проектам',
        },
        group: groups.questionnaires,
        accessKey: 'ROUTE_QUESTIONNAIRES_PROPS'
    }
}, {
    path: '/features/questionnaires',
    component: QuestionnaireFeatures,
    meta: {
        title: 'Фичи',
        sideBar: {
            label: 'Набор элементов',
        },
        group: groups.questionnaires,
        accessKey: 'ROUTE_QUESTIONNAIRES_PROPS_FEATURES'
    }
}, {
    path: '/levels/questionnaires',
    component: QuestionnaireAggregationLevel,
    meta: {
        title: 'Уровни агрегаций',
        sideBar: {
            label: 'Уровни агрегации',
        },
        group: groups.questionnaires,
        accessKey: 'ROUTE_QUESTIONNAIRES_AGG_LEVELS'
    }
}, {
    path: '/levels-features/questionnaires',
    component: QuestionnaireAggregationLevelFeatures,
    meta: {
        title: 'Уровни фич',
        sideBar: {
            label: 'Элементы уровней',
        },
        group: groups.questionnaires,
        accessKey: 'ROUTE_QUESTIONNAIRES_AGG_LEVELS_FEATURES'
    }
}, {
    path: '/aggregations/questionnaires',
    component: QuestionnaireAggregation,
    meta: {
        title: 'Агрегации опросников',
        sideBar: {
            label: 'Агрегации',
        },
        group: groups.questionnaires,
        accessKey: 'ROUTE_QUESTIONNAIRES_AGGS'
    }
}]