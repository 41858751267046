import {BaseApi} from "../baseApi";
import {ServicePorts} from "../../models/Enums";

export class ProjectsApi extends BaseApi{
    constructor() {
        super(ServicePorts.projects)
    }

    static get Name() {
        return 'ProjectsApi'
    }

    get(id) {
        return this.axios.get(`${this.url}/get/${id}`)
    }

    getScreenList(){
        return this.axios.get(`${this.url}/screen_list`)
    }

    getStatus({page = 1, pagesize = 10, project_id, params = {}}){
        let url = `${this.url}/project_outlet_status/${project_id}`;

        return this.axios.get(url, {
            params: Object.assign({ page, pagesize }, params)
        })
    }
    getProjectsFilter({page = 1, pagesize = 10, project = 0,elements = null, order = null, }) {
        let url = `${this.url}/project_filter?page=${page}&pagesize=${pagesize}&project=${project}`;
        if (elements) url += `&elements=${elements.join(",")}`;
        if(order) url += `&order=${order}`;
        return this.axios.get(url)
    }
    exportStatus({project_id, params = {}}){
        let url = `${this.url}/project_outlet_status_export/${project_id}`;

        return this.axios.get(url, {
            responseType: 'blob',
            params
        })
    }


    getActualOutlets(project_id) {
        let url = `${this.url}/project_outlet_actual/${project_id}`;
        return this.axios.get(url)
    }

    getActualOutletsList(project_id) {
        let url = `${this.url}/project_outlet_list/${project_id}`;
        return this.axios.get(url)
    }
    getDate(project_id){
        return this.axios.get(`${this.url}/project_getDate/${project_id}`)
    }


    getStatusOptions(project_id){
        return this.axios.get(`${this.url}/project_status_constructor/${project_id}`)
    }

    addStatusOptions(data){
        return this.axios.post(`${this.url}/project_status_constructor`, data)
    }

    updateStatusOptions(data){
        return this.axios.put(`${this.url}/project_status_constructor/${data.id}`, data)
    }

    getScheduleOptions(project_id){
        return this.axios.get(`${this.url}/project_schedule_constructor/${project_id}`)
    }

    addScheduleOptions(data){
        return this.axios.post(`${this.url}/project_schedule_constructor`, data)
    }

    updateScheduleOptions(data){
        return this.axios.put(`${this.url}/project_schedule_constructor/${data.id}`, data)
    }

    getOutletStatusHistory(outletId, params) {
        return this.axios.get(`${this.url}/status_history/${outletId}`, { params })
    }

    getProjectsLight(search) {
        return this.axios.get(`${this.url}/all/light`, { params: {search} })
    }

    getFilteredLight(params) {
        return this.axios.get(`${this.url}/all/filtered_light`, { params})
    }

    getStartEnd(projectId) {
        return this.axios.get(`${this.url}/start_end/${projectId}`)
    }
}
