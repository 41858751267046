import {ScheduleApi} from "../../../api/entities/scheduleApi";

import projectProperties from '../schedule/modules/projectProperties'
import features from '../schedule/modules/features'
import scheduleOutlets from './modules/scheduleOutlets'
import visits from './modules/visits'
import propertyValues from './modules/propertyValues'

const api = new ScheduleApi();

export default {
    modules: {
        visits, projectProperties, features, scheduleOutlets, propertyValues
    },
    actions: {
        getSchedule({commit}, params) {
            return new Promise( async (resolve, reject) => {
                try {
                    let res = await api.axios.get(api.url + '/schedule_for_site', { params });
                    commit("setSchedule", res.data);
                    resolve(res.data);
                } catch (e) {
                    reject(e)
                }
            });
        },
    },
    mutations: {
        setSchedule(state, schedule) {
            if (!Array.isArray(schedule)) {
                schedule = [schedule]
            }
            state.schedule = schedule
        },
    },
    state: {
        schedule: [],
    },
    getters: {
        schedule: state => state.schedule,
    }
}
