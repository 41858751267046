import groups from "./groups";

const Materials = () => import(/* webpackChunkName: "materials" */ "../views/materials/materials")
const Material = () => import(/* webpackChunkName: "materials" */ "../views/materials/material")
const MaterialsAggregationLevel = () => import(/* webpackChunkName: "materials" */ "../views/materials/aggregation/materials-aggregation-level")
const MaterialsAggregationLevelFeatures = () => import(/* webpackChunkName: "materials" */ "../views/materials/aggregation/materials-aggregation-level-features")
const MaterialsAggregation = () => import(/* webpackChunkName: "materials" */ "../views/materials/aggregation/materials-aggregation")

export default [{
    path: '/materials',
    component: Materials,
    meta: {
        title: 'Материалы',
        sideBar: {
            label: 'Материалы',
        },
        group: groups.materials,
        accessKey: 'ROUTE_MATERIALS'
    }
}, {
    path: '/materials/:id',
    component: Material,
    props: true,
    meta: {
        title: 'Материал',
        group: groups.materials,
        accessKey: 'ROUTE_MATERIALS'
    }
}, {
    path: '/levels/materials',
    component: MaterialsAggregationLevel,
    meta: {
        title: 'Уровни агрегаций',
        sideBar: {
            label: 'Уровни агрегации',
        },
        group: groups.materials,
        accessKey: 'ROUTE_MATERIALS_AGG_LEVELS'
    }
}, {
    path: '/levels-features/materials',
    component: MaterialsAggregationLevelFeatures,
    meta: {
        title: 'Элементы уровней агрегаций материалов',
        sideBar: {
            label: 'Элементы уровней',
        },
        group: groups.materials,
        accessKey: 'ROUTE_MATERIALS_AGG_LEVELS_FEATURES'
    }
}, {
    path: '/aggregations/materials',
    component: MaterialsAggregation,
    meta: {
        title: 'Агрегации материалов',
        sideBar: {
            label: 'Агрегации',
        },
        group: groups.materials,
        accessKey: 'ROUTE_MATERIALS_AGGS'
    }
}]