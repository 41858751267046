import groups from "./groups";

const Schedule = () => import(/* webpackChunkName: "schedule" */ "../views/schedule/schedule")
const ScheduleProperties = () => import(/* webpackChunkName: "schedule" */ "../views/schedule/schedule-properties")
const ScheduleFeatures = () => import(/* webpackChunkName: "schedule" */ "../views/schedule/schedule-features")

export default [{
    path: '/schedule',
    component: Schedule,
    meta: {
        title: 'Расписание',
        sideBar: {
            label: 'Расписание',
        },
        group: groups.schedule,
        accessKey: 'ROUTE_SCHEDULE'
    }
}, {
    path: '/properties/schedule',
    component: ScheduleProperties,
    meta: {
        title: 'Свойства расписаний',
        sideBar: {
            label: 'Свойства',
        },
        group: groups.schedule,
        accessKey: 'ROUTE_SCHEDULE_PROPS'
    }
}, {
    path: '/features/schedule',
    component: ScheduleFeatures,
    meta: {
        title: 'Набор элементов',
        sideBar: {
            label: 'Набор элементов',
        },
        group: groups.schedule,
        accessKey: 'ROUTE_SCHEDULE_PROPS_FEATURES'
    }
}]