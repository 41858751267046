<template>
    <v-file-input
            v-model="control.value"
            :label="control.settings.label"
            :placeholder="control.settings.placeholder"
            :counter="control.settings.counter"
            :chips="control.settings.chips"
            :multiple="control.settings.multiple"
            :accept="control.settings.accept"
            :disabled="control.settings.disabled"
            :rules="control.validation.rules"
            :validate-on-blur="control.validation.validateOnBlur"
            @change="control.settings.func($event, control)"
    >
    </v-file-input>
</template>

<script>
    export default {
        name: "df-v-file-input",
        props: {
            control: Object
        }
    }
</script>
