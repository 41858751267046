<template>
    <div>
        <v-card v-if="card" @keyup.enter="handleSubmit">
            <v-container :class="contClass">
                <div>
                    <slot name="header"/>
                </div>
                <div>
                    <v-form v-model="valid"  :class="formClass">
                        <div>
                            <slot name="appendControls"/>
                        </div>
                        <div>
                            <controls-factory v-for="control in controls" :key="control.key" :control="control"/>
                        </div>
                        <div>
                            <slot name="beforeButtons"/>
                        </div>
                        <v-row no-gutters>
                            <v-col>
                                <slot name="errors"/>
                            </v-col>
                            <v-col cols="auto" v-if="submit">
                                <v-btn class="primary" :disabled="!valid || submitDisabled" @click="handleSubmit">{{submit}}</v-btn>
                            </v-col>
                            <v-col cols="auto" v-if="defaults">
                                <v-btn class="ml-3" @click="handleSetDefaults">{{defaults}}</v-btn>
                            </v-col>
                            <v-col cols="auto" v-if="customBtn">
                                <v-btn class="ml-3" @click="handleCustom">{{customBtn}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
                <div>
                    <slot name="footer"/>
                </div>
            </v-container>
        </v-card>

        <v-form v-model="valid" :class="formClass" v-if="!card">
            <div>
                <slot name="appendControls"/>
            </div>
            <div>
                <controls-factory v-for="control in controls" :key="control.key" :control="control"/>
            </div>
            <div>
                <slot name="beforeButtons"/>
            </div>
            <v-row no-gutters>
                <v-col>
                    <slot name="errors"/>
                </v-col>
                <v-col cols="auto" v-if="submit">
                    <v-btn depressed class="primary" :disabled="!valid || submitDisabled" @click="handleSubmit">{{submit}}</v-btn>
                </v-col>
                <v-col cols="auto" v-if="defaults">
                    <v-btn depressed class="ml-3" @click="handleSetDefaults">{{defaults}}</v-btn>
                </v-col>
                <v-col cols="auto" v-if="customBtn">
                    <v-btn depressed class="ml-3" @click="handleCustom">{{customBtn}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
    import ControlsFactory from "./dynamic-form-components/controls-factory";
    import {ControlType} from "./controls";

    export default {
        name: "dynamic-form",
        components: {ControlsFactory},
        props: {
            submit: null,
            defaults: null,
            customBtn: null,
            card: {
                type: Boolean,
                default: true
            },
            formClass:{
                type: String,
                default: "pa-0"
            },
            contClass:{
                type: String,
                default: "pa-7"
            },
            controls: {
                type: Array,
                required: true
            },
            submitDisabled: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                valid: true
            }
        },
        computed: {
            result: function () {
                let res = {};
                this.controls.forEach(c => Object.assign(res, {[c.key]: c.value}));
                return res
            }
        },
        methods: {
            handleSubmit: function () {
                if(this.valid)
                    this.$emit('submit', this.result);
            },
            handleSetDefaults: function () { // TODO: проверить, чё тут вообще происходит
                this.controls.forEach(c => {
                    switch (c.type) {
                        case ControlType.TextField || ControlType.TextArea:
                            c.value = '';
                            break;
                        case ControlType.FileInput:
                            c.value = [];
                            break;
                        case ControlType.ColorPicker:
                            c.value = '#6600ff';
                            break;
                        case ControlType.CheckBox:
                            c.value = false;
                            break;
                        case ControlType.DatePicker:
                            c.value = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' + ('0' + new Date().getDate()).slice(-2);
                            break;
                        case ControlType.Select:
                            c.value = null;
                            break;
                        case ControlType.Autocomplete:
                            c.value = null;
                            break;
                        case ControlType.NumberField:
                            c.value = 0;
                            break;
                        case ControlType.Slider:
                            c.value = 0;
                            break;
                    }
                })
            },
            handleCustom: function () {
                this.$emit('custom:clicked')
            }
        }
    }
</script>
