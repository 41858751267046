<template>
    <div>
        unknown control
    </div>
</template>

<script>
    export default {
        name: "df-unknown-control"
    }
</script>
