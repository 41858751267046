<template>
    <div>
        Страница не найдена
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>
