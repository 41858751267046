<template>
    <v-slider
            v-model="control.value"
            :label="control.settings.label"
            :min="control.settings.min"
            :max="control.settings.max"
            :vertical="control.settings.vertical"
            :disabled="control.settings.disabled"
            :rules="control.validation.rules"
            :validate-on-blur="control.validation.validateOnBlur"
            @change="control.settings.func($event, control)"
            thumb-label>
    </v-slider>
</template>

<script>
    export default {
        name: "df-v-slider",
        props: {
            control: Object
        }
    }
</script>
