const axios = require('axios').default;
import router from "../router/router";
import store from '../store/index'

class Settings {
    static get Params() {
        return Object.freeze({
            'baseUrl' : process.env.VUE_APP_BASE_URL,
            'Access-Control-Allow-Origin': process.env.VUE_APP_BASE_URL + ', ' + process.env.VUE_APP_BASE_API_URL,
            'baseApiUrl' : process.env.VUE_APP_BASE_API_URL,
        })
    }
}

const params = Settings.Params;

export class BaseApi {
    constructor(port) {
        this.axios = axios;
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if(error.response && error.response.status === 403){
                store.dispatch('removeToken');

                const {name, path} = router.currentRoute
                if(!['root', 'login', 'forbidden', 'not-found'].includes(name)){
                    store.dispatch('setReturnPath', path)
                }

                if (name !== 'login')
                    router.push({name: 'login'})
            }
            return Promise.reject(error);
        });

        const userData = JSON.parse(localStorage.getItem('user-data'));
        if(userData)
            this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + userData.token;

        this.axios.defaults.headers.common['Access-Control-Allow-Origin'] = params["Access-Control-Allow-Origin"];
        this.port = port;
        this.url = params.baseUrl + port;
        this.apiUrl = params.baseApiUrl
    }

    static get Name() {
        return 'BaseApi'
    }

    add(object) {
        return this.axios.post(`${this.url}/add`, object)
    }

    getAll({page = 1, pagesize = 10, order = null, search = "",project = null, status = "", material = "",aggregations = null, params= {}}){
        let url = `${this.url}/all?page=${page}&pagesize=${pagesize}`;
        if(order) url += `&order=${order}`;
        if(search) url += `&search=${search}`;
        if((project) || project === 0) url += `&project=${project}`;
        if(status) url += `&status=${status}`;
        if(aggregations) url += `&aggregations=${aggregations}`;
        if(material) url += `&materials=${material}`;
        if(Object.keys(params).length > 0){
            for(let k in params){
                if(params[k] || (k == 'project' && params[k] === 0))
                    url += `&${k}=${params[k]}`;
            }
        }
        return this.axios.get(url)
    }

    getAllByParams(params){
        return this.axios.get(`${this.url}/`+ this.path, {params})
    }

    get(id, projectId = 0, projectProperties = null) {
        return this.axios.get(`${this.url}/get/${id}`, {
            params: {
                project: projectId,
                zeroProjectProperties: projectProperties
            }
        })
    }

    update(id, updatedObject) {
        return this.axios.put(`${this.url}/update/${id}`, updatedObject)
    }
    updateUser(id, updatedObject){
        return this.axios.put(`${this.url}/updateScreen/${id}`, updatedObject)
    }

    delete(id){
        return this.axios.delete(`${this.url}/delete/${id}`)
    }

    disconnect(aggregation_id){
        return this.axios.delete(this.url + '/aggregation/'+aggregation_id+'/connection');
    }

    getAllAggregationsLevels({page = 1, pagesize = 10, project = 0,elements = null, order = null, }) {
        let url = `${this.url}/aggregation_level_family?page=${page}&pagesize=${pagesize}&project=${project}`;
        if (elements) url += `&elements=${elements.join(",")}`;
        if(order) url += `&order=${order}`;
        return this.axios.get(url)
    }

    getAllAggregationsLevelsFast(params) {
        let url = `${this.url}/aggregation_level_family_fast`
        return this.axios.get(url, { params })
    }

    getAggregationElements(levelId, params = {}) {
        return this.axios.get(`${this.url}/aggregation_elements/level/${levelId}`, {params})
    }
}
