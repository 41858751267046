import {BaseApi} from "../baseApi";
import {ServicePorts} from "../../models/Enums";

export class ScheduleApi extends BaseApi{
    constructor() {
        super(ServicePorts.schedule)
    }

    getRouting(params) {
        return this.axios.get(this.url + '/routing', {
            params: params
        })
    }

    getNewOutlets(params) {
        return this.axios.get(this.url + '/routing/newOutlets', {
            params: params
        })
    }

    getMyRoutes() {
        return this.axios.get(this.url + '/routing/routes')
    }

    changeRoute(params) {
        const { toRoute, outletId, withProjects, fromRoute } = params;
        return this.axios.put(this.url + '/routing/changeRoute', {}, {
            params: {
                toRoute,
                outletId,
                withProjects,
                fromRoute
            }
        })
    }

    /**
     * Включить или отключить посещение точки по проекту на определённую дату
     * @param projectId {Number} Айди проекта
     * @param outletId {Number} Айди точки
     * @param date {string} Дата в формате 2020-01-25
     * @param check {Boolean} Означает, включаем ли мы галочку на этот день или нет
     */
    toggleDate({projectId, outletId, date, check}) {
        return this.axios.put(this.url + '/routing/toggleDate', {
            projectId: projectId,
            outletId: outletId,
            date: date,
            check: check
        })
    }

    attachOutlet({outletId, routeId, projectsData}) {
        return this.axios.post(this.url + '/routing/attachOutlet', {
            outletId,
            routeId,
            projectsData
        })
    }

    getTsmSvs(params = {}) {
        return this.axios.get(this.url + '/routing/svs', {
            params: params
        })
    }

    saveUserTerritory(territoryData) {
        const {userId, territory, color, id} = territoryData;
        const body = {
            id,
            user_id: userId,
            territory, color
        };
        return this.axios.post(this.url + '/routing/territory', body)
    }

    deleteUserTerritory(territoryId) {
        return this.axios.delete(this.url + '/routing/territory/' + territoryId)
    }

    deleteOutletFromRoute(params) {
        return this.axios.put(this.url + '/routing/moveFromRoute', {}, {
            params: params
        })
    }

    getUsersTerritories() {
        return this.axios.get(this.url + '/routing/territories')
    }

    getHmPriceData(filters) {
        return this.axios.get(`${this.url}/hm_price`, {
            params: filters
        })
    }

    getHmPriceNewItemOutlets() {
        return this.axios.get(`${this.url}/accessed_outlets`)
    }

    getHmPriceNewItemUsers(params) {
        const {route_id, date} = params;
        return this.axios.get(`${this.url}/users_by_route`, {
            params: {
                route_id, date
            }
        })
    }

    updateHmPriceItem(item) {
        return this.axios.put(`${this.url}/hm_price`, item)
    }

    acceptRouting() {
        return this.axios.post(`${this.url}/routing/accept_routing`)
    }

    rejectAcceptance(id) {
        return this.axios.post(`${this.url}/routing/reject_acceptance/${id}`)
    }

    canAccept() {
        return this.axios.get(`${this.url}/routing/can_accept`)
    }

    getTsmSummary() {
        return this.axios.get(`${this.url}/routing/tsm_summary`)
    }

    get Name() {
        return 'ScheduleApi'
    }

    static get Name() {
        return 'ScheduleApi'
    }

    getHMProjectFilters() {
        return this.axios.get(`${this.url}/hm_price/projects`)
    }

    getHMOutletFilters(project_id = 0) {
        return this.axios.get(`${this.url}/hm_price/outlets?project_id=${project_id}`)
    }

    getHMRouteFilter(project_id = 0, outlet_id = 0) {
        return this.axios.get(`${this.url}/hm_price/routes?project_id=${project_id}&outlet_id=${outlet_id}`)
    }

    getProjectOutlets(project_id, search="") {
        let url = `${this.url}/project_outlets?project_id=${project_id}`
        if (search) url = url + `&search=${search}`
        return this.axios.get(url)
    }
}
