export default {
    reports: {
        name: 'Отчетность',
        icon: 'mdi-file-chart'
    },
    projects: {
        name: 'Проекты',
        icon: 'mdi-home'
    },
    sku: {
        name: 'SKU',
        icon: 'mdi-receipt'
    },
    users: {
        name: 'Пользователи',
        icon: 'mdi-account-group'
    },
    routes: {
        name: 'Маршруты',
        icon: 'mdi-routes'
    },
    outlets: {
        name: 'Торговые точки',
        icon: 'mdi-map-marker'
    },
    schedule: {
        name: 'Расписание',
        icon: 'mdi-calendar-multiple'
    },
    questionnaires: {
        name: 'Опросники',
        icon: 'mdi-comment-question-outline'
    },
    reasons: {
        name: 'Причины',
        icon: 'mdi-clipboard-text-off-outline'
    },
    materials: {
        name: 'Материалы',
        icon: 'mdi-file-multiple-outline'
    },
    imports: {
        name: 'Загрузка данных',
        icon: 'mdi-database-import-outline'
    },
    exports: {
        name: 'Выгрузка',
        icon: 'mdi-database-export-outline'
    },
    admin: {
        name: 'Администрирование',
        icon: 'mdi-shield-crown-outline'
    },
    notification: {
        name: 'Уведомления на телефоне',
        icon: 'mdi-message-badge-outline'
    }
}