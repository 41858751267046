<template>
    <v-textarea
            v-model="control.value"
            :label="control.settings.label"
            :placeholder="control.settings.placeholder"
            :counter="control.settings.counter"
            :disabled="control.settings.disabled"
            :auto-grow="control.settings.autoGrow"
            :rows="control.settings.rows"
            :rules="control.validation.rules"
            :validate-on-blur="control.validation.validateOnBlur"
            @change="control.settings.func($event, control)"
            :class="getClass"
    >
    </v-textarea>
</template>

<script>
    export default {
        name: "df-v-textarea",
        props: {
            control: Object
        },
        computed: {
            getClass() {
                if (this.control.settings.invisible === true) {
                    return 'd-none';
                }
                return ""
            }
        }
    }
</script>
