import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ru from 'vuetify/es5/locale/ru'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light:  {
                primary: '#f44336',
                secondary: '#7997a5',
                accent: '#4caf50',
                error: '#971aff',
                warning: '#ff9800',
                info: '#2196f3',
                success: '#009688'
            }
        }
    },
    lang: {
        locales: { ru, en },
        current: navigator.language.split('-')[0]
    },
});
