import groups from "./groups";

const Reason = () => import(/* webpackChunkName: "reasons" */ "../views/reasons/reason")
const Reasons = () => import(/* webpackChunkName: "reasons" */ "../views/reasons/reasons")
const ReasonAggregationLevel = () => import(/* webpackChunkName: "reasons" */ "../views/reasons/aggregation/reason-aggregation-level")
const ReasonAggregationFeature = () => import(/* webpackChunkName: "reasons" */ "../views/reasons/aggregation/reason-aggregation-level-features")
const ReasonAggregation = () => import(/* webpackChunkName: "reasons" */ "../views/reasons/aggregation/reason-aggregation")
const ReportReviewReasons = () => import(/* webpackChunkName: "reasons" */ "../views/reasons/report_review_reasons")
const ReportReviewReason = () => import(/* webpackChunkName: "reasons" */ "../views/reasons/report_review_reason")

export default [{
    path: '/reasons/:id',
    component: Reason,
    meta: {
        title: 'Причины',
        group: groups.reasons,
        accessKey: 'ROUTE_REASONS'
    }
}, {
    path: '/reasons',
    component: Reasons,
    meta: {
        title: 'Причины',
        sideBar: {
            label: 'Причины',
        },
        group: groups.reasons,
        accessKey: 'ROUTE_REASONS'

    }
}, {
    path: '/levels/reasons',
    component: ReasonAggregationLevel,
    meta: {
        title: 'Уровни агрегаций',
        sideBar: {
            label: 'Уровни агрегации',
        },
        group: groups.reasons,
        accessKey: 'ROUTE_REASONS_AGG_LEVELS'
    }
}, {
    path: '/levels-features/reasons',
    component: ReasonAggregationFeature,
    meta: {
        title: 'Элементы уровней',
        sideBar: {
            label: 'Элементы уровней',
        },
        group: groups.reasons,
        accessKey: 'ROUTE_REASONS_AGG_LEVELS_FEATURES'
    }
}, {
    path: '/aggregations/reasons',
    component: ReasonAggregation,
    meta: {
        title: 'Агрегации причин',
        sideBar: {
            label: 'Агрегации',
        },
        group: groups.reasons,
        accessKey: 'ROUTE_REASONS_AGGS'
    }
}, {
    path: '/report_review_reasons',
    component: ReportReviewReasons,
    meta: {
        title: 'Причины непринятия отчетов',
        sideBar: {
            label: 'Причины непринятия отчетов',
        },
        group: groups.reasons,
        accessKey: 'ROUTE_REASONS_REPORT_REVIEW'
    }
}, {
    path: '/report_review_reasons/:id',
    component: ReportReviewReason,
    meta: {
        title: 'Причины непринятия отчетов',
        group: groups.reasons,
        accessKey: 'ROUTE_REASONS_REPORT_REVIEW'
    }
}]