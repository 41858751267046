export const toRuDateStringFilter = date =>
    date ? new Date(date).toLocaleDateString('ru') : undefined

export const minutesToHhMmFilter = minutes => {
    const date = new Date(0, 0, 0, 0, minutes);
    let res = date.toTimeString().substring(0, 5);
    const days = date.getDay();
    if(days) {
        const hours = Number(res.substring(0, 2));
        const totalHours = 24 * days + hours;
        res = totalHours + res.substring(2,5);
    }
    return res;
}