import {OutletsApi} from "../../api/entities/outletsApi";

const api = new OutletsApi();

export default {
    actions: {
        getOutlets: ({commit}) => {
            return new Promise(async (resolve, reject) => {
                try {
                    let res = await api.getAll({pagesize: Number.MAX_SAFE_INTEGER});
                    commit('getOutlets', res.data);
                    resolve(res.data)
                } catch (e) {
                    reject(e)
                }
            })
        }
    },
    mutations: {
        getOutlets: (state, outlets) => {
            state.outlets = outlets
        }
    },
    state: {
        outlets: []
    },
    getters: {
        outlets: state => state.outlets
    }
}
