import {Gender, RoleType, Status} from "../Enums";
import {AutocompleteAttribute, SelectAttribute, TextFieldAttribute} from "../Attributes";
import {Entity} from "./Entity";
import Rules from "../../components/common/dynamic-form/rules";
import {RolesApi} from "../../api/entities/rolesApi";

export class User extends Entity{
    constructor() {
        super();

        this.attributes = [
            new TextFieldAttribute('login').AddSettings({label: 'Логин'}).AddRules({rules: Rules.Login(), validateOnBlur: true}),
            new TextFieldAttribute('password', {visible: true, edit: true}).AddSettings({label: 'Пароль', password: true, displayGenerator: true, displayEye: true, placeholder: '12AaBb'}),
            new TextFieldAttribute('surname').AddSettings({label: 'Фамилия'}).AddRules({rules: Rules.Required()}),
            new TextFieldAttribute('name').AddSettings({label: 'Имя'}).AddRules({rules: Rules.Required()}),
            new TextFieldAttribute('patronymic').AddSettings({label: 'Отчество (при наличии)'}),
            new TextFieldAttribute('presentation').AddSettings({label: 'Обращение'}),
            new SelectAttribute('role_type', {visible: false}).AddSettings({label: 'Тип роли'}).AddOptions({options: RoleType, itemText: 'rus', itemValue: 'id'}),
            new AutocompleteAttribute('role_id', {visible: false})
                .AddSettings({label: 'Роль', chips: true})
                .AddSource({promise: new RolesApi().getAll({pagesize: Number.MAX_SAFE_INTEGER}), itemValue: 'id', itemText: 'name'})
                .AddRules({rules: Rules.SelectNull()}),
            new TextFieldAttribute('role_name', {add: false, edit: false}).AddSettings({label: 'Роль'}),
            new TextFieldAttribute('email').AddSettings({label: 'Почта'}),
            new TextFieldAttribute('phone').AddSettings({label: 'Телефон'}),
            new SelectAttribute('status').AddSettings({label: 'Статус'}).AddOptions({options: Status, itemText: 'rus', itemValue: 'id'}).SetValue(Status[0].id),
            new SelectAttribute('gender').AddSettings({label: 'Пол'}).AddOptions({options: Gender, itemText: 'rus', itemValue: 'id'}).SetValue(Gender[0].id),
        ];
    }

    fromJson(json) {
        super.fromJson(json);
        this.attributes.find(a => a.key === "password").AddRules({rules: Rules.Password(), validateOnBlur: true});
        return this;
    }

    setEventListener() {
        this.attributes.find(a => a.key === 'role_type').func = () => {
            let val = this.controls.find(c => c.key === 'role_type').value;
            let getId = new RolesApi().getAll({pagesize: Number.MAX_SAFE_INTEGER, params: {roleType: val}}).then(({data, ...rest})=> {
                return{
                    ...rest,
                    data: data.filter((item) => item.type === val)
                }
            });
            this.controls.find(c => c.key === 'role_id')
                .AddSource({promise: getId, itemValue: 'id', itemText: 'name'})
        };

        return this;
    }

    static get Table() {
        let _align = 'center', _sortable = false, _filterable = false, _divider = true, _class = undefined,
            _width = null, _filter = null, _sort = null;
        return [
            {text: 'Id', value: 'id' , align: _align, sortable: true, filterable: _filterable, divider: _divider, class: _class, width: _width, filter: _filter, sort: _sort},
            {text: 'Presentation', value: 'presentation', align: _align, sortable: true, filterable: _filterable, divider: _divider, class: _class, width: _width, filter: _filter, sort: _sort},
            {text: 'Логин', value: 'login', align: _align, sortable: _sortable, filterable: _filterable, divider: _divider, class: _class, width: _width, filter: _filter, sort: _sort},
            {text: 'Фамилия', value: 'surname', align: _align, sortable: true, filterable: _filterable, divider: _divider, class: _class, width: _width, filter: _filter, sort: _sort},
            {text: 'Имя', value: 'name', align: _align, sortable: true, filterable: _filterable, divider: _divider, class: _class, width: _width, filter: _filter, sort: _sort},
            {text: 'Отчество', value: 'patronymic', align: _align, sortable: true, filterable: _filterable, divider: _divider, class: _class, width: _width, filter: _filter, sort: _sort},
            {text: 'Роль', value: 'role_name', align: _align, sortable: _sortable, filterable: _filterable, divider: _divider, class: _class, width: _width, filter: _filter, sort: _sort},
            {text: 'Статус', value: 'status', align: _align, sortable: _sortable, filterable: _filterable, divider: _divider, class: _class, width: _width, filter: _filter, sort: _sort},
            {text: 'Выгрузка автостатуса', value: 'property', align: _align, sortable: _sortable, filterable: _filterable, divider: _divider, class: _class, width: _width, filter: _filter, sort: _sort},
        ];
    }

    static get CurrentUser(){
        return JSON.parse(localStorage.getItem('payload'))
    }

    static get IsAuthorized(){
        return !!this.CurrentUser
    }

    get Name() {
        return "User"
    }

    static get Name() {
        return "User"
    }
}
