import router from "../../router/router";
export default {
    actions: {
        setFilter: (context, data) => {
            context.commit('setFilter', data);
        },
        resetFilter: (context, name) => {
            context.commit('resetFilter',name);
        },
        setRoute: (context) => {
            context.commit('setRoute');
        },
        resetAllFilters({commit}) {
            commit('resetAllFilters')
        }
    },
    mutations: {
        setFilter: (state, filter) => {
            if(!filter.name){
                return;
            }
            let route = router.currentRoute.path.split("/")[1];
            filter.route = route;
            let index = state.filters.findIndex(f => f.name === filter.name && f.route === filter.route);
            if (index > -1)
                state.filters.splice(index, 1);
            state.filters.push(filter);
        },
        resetFilter: (state, name) => {
            let route = router.currentRoute.path.split("/")[1];
            let index = state.filters.findIndex(f => f.name === name && f.route === route);
            if (index > -1)
                state.filters.splice(index, 1);
        },
        setRoute: state => {
            let route = router.currentRoute.path.split("/")[1];
            state.route = route;
        },
        resetAllFilters(state) {
            state.filters = [];
            state.route = null
        }
    },
    state: {
        filters: [],
        route: null,
    },
    getters: {
        getFilters: state => {
            return state.filters.filter(s => s.route === state.route);
        },
        route: state => state.route
    }
}
