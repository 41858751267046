import groups from "./groups";

const User = () => import(/* webpackChunkName: "users" */ "../views/users/user")
const Users = () => import(/* webpackChunkName: "users" */ "../views/users/users")
const UserProjectProperties = () => import(/* webpackChunkName: "users" */ "../views/users/user-project-properties")
const UserFeatures = () => import(/* webpackChunkName: "users" */ "../views/users/user-features")
const UserAggregationLevel = () => import(/* webpackChunkName: "users" */ "../views/users/aggregation/user-aggregation-level")
const UserAggregationLevelFeatures = () => import(/* webpackChunkName: "users" */ "../views/users/aggregation/user-aggregation-level-features")
const UserAggregation = () => import(/* webpackChunkName: "users" */ "../views/users/aggregation/user-aggregation")
const Roles = () => import(/* webpackChunkName: "users" */ "../views/users/role/user-roles")
const Role = () => import(/* webpackChunkName: "users" */ "../views/users/role/user-role")
const UserRoutes = () => import(/* webpackChunkName: "users" */ "../views/users/user-routes")

export default [{
    path: '/users/:id',
    component: User,
    name: 'user',
    meta: {
        title: 'Пользователь',
        group: groups.users,
        accessKey: 'ROUTE_USERS'
    },
}, {
    path: '/users',
    component: Users,
    meta: {
        title: 'Пользователи',
        sideBar: {
            label: 'Все пользователи',
        },
        group: groups.users,
        accessKey: 'ROUTE_USERS'
    }
}, {
    path: '/project-properties/users',
    component: UserProjectProperties,
    meta: {
        title: 'Свойства пользователей по проекту',
        sideBar: {
            label: 'Свойства по проектам',
        },
        group: groups.users,
        accessKey: 'ROUTE_USERS_PROPS'
    }
}, {
    path: '/features/users',
    component: UserFeatures,
    meta: {
        title: 'Набор элементов',
        sideBar: {
            label: 'Набор элементов',
        },
        group: groups.users,
        accessKey: 'ROUTE_USERS_PROPS_FEATURES'
    }
}, {
    path: '/levels/users',
    component: UserAggregationLevel,
    meta: {
        title: 'Уровни агрегаций',
        sideBar: {
            label: 'Уровни агрегации',
        },
        group: groups.users,
        accessKey: 'ROUTE_USERS_AGG_LEVELS'
    }
}, {
    path: '/levels-features/users',
    component: UserAggregationLevelFeatures,
    meta: {
        title: 'Уровни фич',
        sideBar: {
            label: 'Элементы уровней',
        },
        group: groups.users,
        accessKey: 'ROUTE_USERS_AGG_LEVELS_FEATURES'
    }
}, {
    path: '/aggregations/users',
    component: UserAggregation,
    meta: {
        title: 'Агрегации пользователей',
        sideBar: {
            label: 'Агрегации',
        },
        group: groups.users,
        accessKey: 'ROUTE_USERS_AGGS'
    }
}, {
    path: '/roles',
    component: Roles,
    meta: {
        title: 'Роли пользователей',
        sideBar: {
            label: 'Роли пользователей',
        },
        group: groups.users,
        accessKey: 'ROUTE_USERS_ROLES'
    }
}, {
    path: '/roles/:id',
    component: Role,
    meta: {
        title: 'Роль пользователей',
        group: groups.users,
        accessKey: 'ROUTE_USERS_ROLES'
    }
}, {
    path: '/user-routes/',
    component: UserRoutes,
    meta: {
        title: 'Привязка пользователей',
        sideBar: {
            label: 'Привязка пользователей',
        },
        group: groups.users,
        accessKey: 'ROUTE_USERS_ROTES_BIND'
    }
},
]