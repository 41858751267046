import {UsersApi} from "../../api/entities/usersApi";
import Utils from "../../Utils/Utils";
const api = new UsersApi();

export default {
    actions: {
        login({commit}, loginData) {
            return new Promise(async (resolve, reject) => {
                try {
                    let res = await api.loginAsync(loginData);
                    api.axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
                    localStorage.setItem('user-data', JSON.stringify(res.data));
                    commit('login', res.data);
                    commit('setAccesses', res.data.site_accesses)
                    resolve(res.data)
                } catch (e) {
                    if(e.response.status === 404)
                        reject('Пользователь не найден');
                    if(e.response.status === 407)
                        reject(407);
                    else
                        reject(e)
                }
            })
        },
        logout({commit}) {
            return new Promise(async (resolve, reject) => {
                try {
                    let res = await api.logoutAsync();
                    localStorage.removeItem('user-data');
                    commit('logout');
                    commit('setAccesses', [])
                    commit('setReturnPath', undefined)
                    commit('resetAllFilters')
                    resolve(res.data)
                } catch (e) {
                    reject(e)
                }
            })
        },
        changePassword({dispatch}, formData) {
            return new Promise((resolve, reject) => {
                api.changePassword(formData)
                    .then(({data: login}) => {
                        dispatch('login', {
                            login,
                            password: atob(formData.get('password').toString())
                        })
                        resolve(login)
                    })
                    .catch(e => {
                        reject(e)
                    })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        resetPassword({}, userId) {
            return new Promise((resolve, reject) => {
                api.resetPassword(userId)
                    .then(() => resolve())
                    .catch(e => reject(e))
            })
        },
        removeToken({commit}){
            localStorage.removeItem('user-data');
            commit('removeToken');
        },
        changePresentation({commit}, newPresentation) {
            commit('changePresentation', newPresentation)
        }
    },
    mutations: {
        login: (state, userData) => state.userData = userData,
        logout: state =>  state.userData = '',
        removeToken: state =>  state.userData = '',
        changePresentation(state, newPresentation) {
            state.userData.user_name = newPresentation
        },
        setAccesses(state, accesses) {
            state.accesses = accesses
        }
    },
    state: {
        userData: localStorage.getItem('user-data') || '',
        accesses: []
    },
    getters: {
        accesses: state => state.accesses,
        tokenValid: (state, getters) => {
            if(getters.isAuthenticated && getters.token){
                 let parsedToken = Utils.parseJwt(getters.token);
                 let curdate = new Date();
                 let expirDate = new Date(parsedToken.CurDate);
                 expirDate.setDate(expirDate.getDate() + 1);
                 return  expirDate.getTime() > curdate.getTime()
            }
            return false;
        },
        isAuthenticated: state => !!state.userData,
        userId: (state, getters) => getters.isAuthenticated ? state.userData.user_id : '',
        userName: (state, getters) =>  getters.isAuthenticated ? state.userData.user_name : '',
        userRole: (state, getters) =>  getters.isAuthenticated ? state.userData.user_role : '',
        roleType: (state, getters) =>  getters.isAuthenticated ? state.userData.role_type : '',
        token: (state, getters) =>  getters.isAuthenticated ? state.userData.token : '',
        strongPassword: (state, getters) =>  getters.isAuthenticated ? state.userData.strong_password : '',
    }
}
