<template>
    <div class="x-yandex-coords">
        <v-text-field
                :value="control.value | toString"
                :label="control.settings.label"
                :disabled="control.settings.disabled"
                @change="control.settings.func($event, control)"
                readonly @click="openMap">
        </v-text-field>
        <v-dialog v-model="mapDialog" max-width="800">
            <v-card>
                <v-card-title class="headline">Выберите адрес торговой точки</v-card-title>
                <v-card-text>
                    <yandex-map :settings="ymapSettings" :coords="control.value" :controls="ymapControls" @click="handleMapClick" class="map">
                        <ymap-marker :coords="markerCoords" marker-id="0"/>
                    </yandex-map>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="red" @click="saveCoordinates" text>Сохранить</v-btn>
                    <v-btn color="grey" @click="closeMap" text>Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {yandexMap, ymapMarker} from 'vue-yandex-maps'

    export default {
        name: "x-yandex-coords",
        components: {yandexMap, ymapMarker},
        computed: mapGetters(["ymapSettings", "ymapControls"]),
        props: {
            control: Object
        },
        data: function () {
            return {
                mapDialog: false,
                markerCoords: this.control.value,
            }
        },
        methods: {
            openMap: function () {
                this.mapDialog = true;
            },
            closeMap: function () {
                this.mapDialog = false;
            },
            saveCoordinates: function() {
                this.control.value = this.markerCoords;
                this.mapDialog = false;
            },
            handleMapClick: function (e) {
                this.markerCoords = e.get('coords');
            }
        },
        filters: {
            toString: function (coords) {
                let length = 7;
                return String(coords[0]).substr(0, length) + ', ' + String(coords[1]).substr(0, length)
            }
        },
    }
</script>
<style scoped>
    .map {
        height: 500px;
        width: auto;
    }
</style>
