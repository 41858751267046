import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import ymap from './modules/ymap'
import outlets from './modules/outlets'
import auth from './modules/auth'
import schedule from './modules/schedule/schedule'
import projects from './modules/projects'
import filter from "./modules/filter";
import globals from './modules/globals'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        ymap, outlets, auth, schedule, projects, filter, globals
    },
    plugins: [createPersistedState({
        paths: [ 'auth', 'globals' ]
    })]
});
