<template>
    <v-menu ref="menu"
            v-model="show"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="control.value"
            transition="slide-y-transition"
            offset-y
            max-width="290px"
            min-width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                    :value="control.value | timeString"
                    :label="control.settings.label"
                    :disabled="control.settings.disabled"
                    prepend-icon="access_time"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @change="control.settings.func($event, control)"
            ></v-text-field>
        </template>
        <v-time-picker
                scrollable
                format="24hr"
                v-if="show"
                v-model="control.value"
                full-width
                @click:minute="$refs.menu.save(control.value)"
        ></v-time-picker>
    </v-menu>
</template>

<script>
    export default {
        name: "df-v-time-picker",
        props: {
            control: Object
        },
        data: function () {
            return {
                show: false
            }
        },
        filters: {
            timeString(val) {
                let arr = String(val).split(':');
                return arr[0] + ' ч. ' + arr[1] + ' мин.'
            }
        },
    }
</script>
