import {AttribTypes} from "../../../models/Enums";
import Rules from "./rules";

export const ControlType = Object.freeze({
    'TextField': 'v-text-field',
    'NumberField': 'x-number-field',
    'TextArea': 'v-textarea',
    'Slider': 'v-slider',
    'Select': 'v-select',
    'DatePicker': 'v-date-picker',
    'CheckBox': 'v-checkbox',
    'ColorPicker': 'v-color-picker',
    'FileInput': 'v-file-input',
    'Autocomplete': 'v-autocomplete',
    'CoordinatesPicker': 'x-yandex-coords',
    'TimePicker': 'v-time-picker',
});

export class Control {
    constructor(key, value) {
        this.key = key;
        this.value = value;
        this.settings = {};
        this.validation = {};
    }

    AddRules({rules = [], validateOnBlur = false}){
        this.validation.rules = rules;
        this.validation.validateOnBlur = validateOnBlur;
        return this
    }
}

export class TextField extends Control {
    constructor(key, value = '') {
        super(key, value);
        this.type = ControlType.TextField;
    }

    AddSettings({label = '', placeholder = '', counter = false, clearable = false,
                    displayEye = false, displayGenerator = false,
                    disabled = false, invisible = false, password = false, func = () => {},
                    append_icon = '', append_click = () => {}}) {
        this.settings.label = label;
        this.settings.placeholder = placeholder;
        this.settings.counter = counter;
        this.settings.disabled = disabled;
        this.settings.clearable = clearable;
        this.settings.invisible = invisible;
        this.settings.displayEye = displayEye;
        this.settings.displayGenerator = displayGenerator;
        this.settings.password = password;
        this.settings.func = func;
        this.settings.append_icon = append_icon;
        this.settings.append_click = append_click;
        return this;
    }
}

export class NumberField extends Control {
    constructor(key, value = 0) {
        super(key, value);
        this.type = ControlType.NumberField;
    }

    AddSettings({label = '', placeholder = '', counter = false, clearable = false,
                    displayGenerator = false, displayEye = false,
                    disabled = false, min = false, max = false, step = false, func = () => {}}) {
        this.settings.label = label;
        this.settings.placeholder = placeholder;
        this.settings.counter = counter;
        this.settings.disabled = disabled;
        this.settings.clearable = clearable;
        this.settings.displayGenerator = displayGenerator;
        this.settings.displayEye = displayEye;
        this.settings.min = min;
        this.settings.max = max;
        this.settings.step = step;
        this.settings.func = func;
        return this;
    }
}

export class TextArea extends Control {
    constructor(key, value = '') {
        super(key, value);
        this.type = ControlType.TextArea;
    }

    AddSettings({label = '', placeholder = '', counter = false, disabled = false, invisible = false, autoGrow = false, rows = 3, func = () => {}}) {
        this.settings.label = label;
        this.settings.placeholder = placeholder;
        this.settings.counter = counter;
        this.settings.autoGrow = autoGrow;
        this.settings.rows = rows;
        this.settings.disabled = disabled;
        this.settings.invisible = invisible;
        this.settings.func = func;
        return this;
    }
}

export class Slider extends Control {
    constructor(key, value = 0) {
        super(key, value);
        this.type = ControlType.Slider;
    }

    AddSettings({label = '', min = 0, max = 100, vertical = false, disabled = false, func = () => {}}) {
        this.settings.label = label;
        this.settings.min = min;
        this.settings.max = max;
        this.settings.vertical = vertical;
        this.settings.disabled = disabled;
        this.settings.func = func;
        return this;
    }
}

export class Select extends Control {
    constructor(key, value = null) {
        super(key, value);
        this.type = ControlType.Select;
        this.settings.func = () => {};
    }

    AddSettings({label = '', multiple = false, disabled = false, func = () => {}}) {
        this.settings.label = label;
        this.settings.multiple = multiple;
        this.settings.disabled = disabled;
        this.settings.func = func;
        return this;
    }

    AddOptions({options = [], itemText = '', itemValue = '',  returnObject = false}){
        this.options = options;
        this.options.returnObject = returnObject;
        this.options.itemText = itemText;
        this.options.itemValue = itemValue;
        return this
    }
}

export class Autocomplete extends Control {
    constructor(key, value = null) {
        super(key, value);
        this.type = ControlType.Autocomplete;
    }

    AddSettings({label = '', multiple = false, disabled = false, chips= false, func = () => {}}) {
        this.settings.label = label;
        this.settings.multiple = multiple;
        this.settings.disabled = disabled;
        this.settings.chips = chips;
        this.settings.func = func;
        return this;
    }


    AddSource({promise, api = null, itemText = '', itemValue = '',  returnObject = false}){
        this.api = api
        this.promise = promise;
        this.options = [];
        this.options.returnObject = returnObject;
        this.options.itemText = itemText;
        this.options.itemValue = itemValue;
        return this
    }
}

export class DatePicker extends Control {
    constructor(key, value = new Date().toISOString()) {
        super(key, value);
        this.type = ControlType.DatePicker;
    }

    AddSettings({label = '', firstDayOfWeek = 1, locale = 'ru', disabled = false, func = () => {}, format = '' }) {
        this.settings.label = label;
        this.settings.firstDayOfWeek = firstDayOfWeek;
        this.settings.locale = locale;
        this.settings.disabled = disabled;
        this.settings.func = func;
        this.settings.format = format;
        return this;
    }

    AddRules({min = undefined, max = undefined}){
        this.validation.min = min;
        this.validation.max = max;
        return this
    }
}

export class TimePicker extends Control {
    constructor(key, value = '00:00') {
        super(key, value);
        this.type = ControlType.TimePicker;
    }

    AddSettings({label = '', disabled = false, func = () => {} }) {
        this.settings.label = label;
        this.settings.disabled = disabled;
        this.settings.func = func;
        return this;
    }
}

export class CoordinatesPicker extends Control {
    constructor(key, value = [55.755814, 37.617635]) {
        super(key, value);
        this.type = ControlType.CoordinatesPicker;
    }

    AddSettings({label = '', disabled = false, func = () => {} }) {
        this.settings.label = label;
        this.settings.disabled = disabled;
        this.settings.func = func;
        return this;
    }
}

export class CheckBox extends Control {
    constructor(key, value = false || "0" || 0) {
        super(key, value);
        this.type = ControlType.CheckBox;
    }

    AddSettings({label = '', trueValue = true, falseValue = false, disabled = false, func = () => {}}) {
        this.settings.label = label;
        this.settings.trueValue = trueValue;
        this.settings.falseValue = falseValue;
        this.settings.disabled = disabled;
        this.settings.func = func;
        return this;
    }
}

export class ColorPicker extends Control {
    constructor(key, value = '#0000FF') {
        super(key, value);
        this.type = ControlType.ColorPicker;
    }

    AddSettings({label = '', disabled = false, func = () => {} }) {
        this.settings.label = label;
        this.settings.disabled = disabled;
        this.settings.func = func;
        return this;
    }
}

export class FileInput extends Control {
    constructor(key, value = []) {
        super(key, value);
        this.type = ControlType.FileInput;
    }

    AddSettings({label = '', placeholder = '', counter = false, chips = false, multiple = false, accept = '*', disabled = false, func = () => {}}) {
        this.settings.label = label;
        this.settings.placeholder = placeholder;
        this.settings.counter = counter;
        this.settings.chips = chips;
        this.settings.multiple = multiple;
        this.settings.accept = accept;
        this.settings.disabled = disabled;
        this.settings.func = func;
        return this;
    }
}

export class Controls {
    static TextField(key, value = '') {
        return new TextField(key, value)
    }

    static NumberField(key, value = null) {
        return new NumberField(key, value);
    }

    static TextArea(key, value = ''){
        return new TextArea(key, value);
    }

    static Slider(key, value = 0){
        return new Slider(key, value);
    }

    static Select(key, value = null){
        return new Select(key, value)
    }

    static Autocomplete(key, value = null){
        return new Autocomplete(key, value)
    }

    static DatePicker(key, value = new Date().toISOString()){
        return new DatePicker(key, value)
    }

    static TimePicker(key, value = '00:00'){
        return new DatePicker(key, value)
    }

    static CoordinatesPicker(key, value = [55.755814, 37.617635]){
        return new CoordinatesPicker(key, value)
    }

    static CheckBox(key, value = false || 0 || "0"){
        return new CheckBox(key, value)
    }

    static ColorPicker(key, value = '#0000FF'){
        return new ColorPicker(key, value)
    }

    static FileInput(key, value = []){
        return new FileInput(key, value)
    }

    static CreateFromAttributes(attributes = []){
        return attributes.map(a => {
            switch (a.type) {
                case AttribTypes.textField:
                    return new TextField(a.key, a.value)
                        .AddSettings({displayEye: a.displayEye, displayGenerator: a.displayGenerator, label: a.label, password: a.password, counter: a.counter, placeholder: a.placeholder, disabled: a.disabled, func: a.func, append_icon: a.append_icon, append_click: a.append_click})
                        .AddRules({rules: a.rules, validateOnBlur: a.validateOnBlur});
                case AttribTypes.number:
                    return new NumberField(a.key, a.value)
                        .AddSettings({label: a.label, counter: a.counter, disabled: a.disabled, placeholder: a.placeholder, func: a.func})
                        .AddRules({rules: a.rules, validateOnBlur: a.validateOnBlur});
                case AttribTypes.textArea:
                    return new TextArea(a.key, a.value)
                        .AddSettings({placeholder: a.placeholder, disabled: a.disabled, counter: a.counter, label: a.label, rows: a.rows, autoGrow: a.autoGrow, func: a.func})
                        .AddRules({validateOnBlur: a.validateOnBlur, rules: a.rules});
                case AttribTypes.slider:
                    return new Slider(a.key, a.value)
                        .AddSettings({label: a.label, disabled: a.disabled, max: a.max, min: a.min, func: a.func})
                        .AddRules({rules: a.rules, validateOnBlur: a.validateOnBlur});
                case AttribTypes.date:
                    return new DatePicker(a.key, a.value)
                        .AddSettings({label: a.label, disabled: a.disabled, firstDayOfWeek: a.firstDayOfWeek, locale: a.locale, func: a.func})
                        .AddRules({min: a.min, max: a.max});
                case AttribTypes.time:
                    return new TimePicker(a.key, a.value)
                        .AddSettings({label: a.label, disabled: a.disabled, func: a.func});
                case AttribTypes.select:
                    return new Select(a.key, a.value)
                        .AddSettings({label: a.label, disabled: a.disabled, multiple: a.multiple, func: a.func})
                        .AddOptions({options: a.options, itemText: a.itemText, itemValue: a.itemValue, returnObject: a.returnObject})
                        .AddRules({rules: a.rules, validateOnBlur: a.validateOnBlur});
                case AttribTypes.checkbox:
                    return new CheckBox(a.key, a.value)
                        .AddSettings({disabled: a.disabled, label: a.label, trueValue: a.trueValue, falseValue: a.falseValue, func: a.func})
                        .AddRules({validateOnBlur: a.validateOnBlur, rules: a.rules});
                case AttribTypes.file:
                    return new FileInput(a.key, a.value)
                        .AddSettings({label: a.label, disabled: a.disabled, counter: a.counter, placeholder: a.placeholder, multiple: a.multiple, chips: a.chips, accept: a.accept, func: a.func});
                case AttribTypes.color:
                    return new ColorPicker(a.key, a.value)
                        .AddSettings({disabled: a.disabled, label: a.label, func: a.func})
                        .AddRules({rules: a.rules, validateOnBlur: a.validateOnBlur});
                case AttribTypes.coordinates:
                    return new CoordinatesPicker(a.key, a.value)
                        .AddSettings({disabled: a.disabled, label: a.label, func: a.func});
                case AttribTypes.autocomplete:
                    return new Autocomplete(a.key, a.value)
                        .AddSettings({label: a.label, disabled: a.disabled, multiple: a.multiple, func: a.func})
                        .AddSource({promise: a.promise, itemText: a.itemText, itemValue: a.itemValue, returnObject: a.returnObject})
                        .AddRules({rules: a.rules, validateOnBlur: a.validateOnBlur});
            }
        });
    }

    static CreateFromProperties(properties = []){
        if (!properties) return []
        return properties.map(p => {
            let alias = p.project_property.alias;
            let feature = p.project_property.feature;
            let required = p.project_property.required;
            switch (p.project_property.feature.type) {
                case 'int':
                    return new NumberField(p.property_id, p.value !== '' ? p.value : 0)
                        .AddSettings({label: alias, placeholder: feature.name})
                        .AddRules({rules: required ? Rules.RequiredNumber().concat(Rules.Integer) : Rules.Integer()});
                case 'float':
                    return new NumberField(p.property_id, p.value !== '' ? p.value : 0)
                        .AddSettings({label: alias, placeholder: feature.name})
                        .AddRules({rules: required ? Rules.RequiredNumber() : Rules.Anarchy()});
                case 'string':
                    return new TextField(p.property_id, p.value)
                        .AddSettings({label: alias})
                        .AddRules({rules: required ? Rules.Required() : Rules.Anarchy() });
                case 'bool':
                    return new CheckBox(p.property_id, p.value !== '' ? p.value : '0')
                        .AddSettings({label: alias, trueValue: '1', falseValue: '0'})
                        .AddRules({rules: required ? Rules.Required() : Rules.Anarchy() });
                case 'enum':
                    return new Select(p.property_id,
                            p.value !== '' ? p.value : p.project_property.feature.enums[0].value)
                        .AddSettings({label: alias})
                        .AddOptions({options: p.project_property.feature.enums, itemText: 'value', itemValue: 'value'})
                        .AddRules({rules: required ? Rules.Required() : Rules.Anarchy() });
                case 'date':
                    return new DatePicker(p.property_id, p.value !== '' ? p.value : new Date().toISOString())
                        .AddSettings({label: alias, format: 'date'});
                case 'time':
                    return new TimePicker(p.property_id, p.value !== '' ? p.value : '00:00')
                        .AddSettings({label: alias});
                case 'photo':
                    return new FileInput(p.property_id, p.value !== '' ? [p.value] : [])
                        .AddSettings({label: alias})
                        .AddRules({rules: required ? Rules.Required() : Rules.Anarchy() });
            }
        });
    }

    static CreateFromAggregationProperties(properties = []){
        let requiredMessage = 'Поле обязательное';
        return properties.map(p => {
            let alias = p.aggregation_level_property.name;
            let feature = p.aggregation_level_property.feature;
            let required = true;
            switch (p.aggregation_level_property.feature.type) {
                case 'int':
                    return new NumberField(p.agg_property_id, p.value !== '' ? p.value : 0)
                        .AddSettings({label: alias, placeholder: feature.name})
                        .AddRules({rules: required ? [v => String(v).length > 0 || requiredMessage, v => v % 1 === 0 || 'Введите целое число'] : [v => v % 1 === 0 || 'Введите целое число']});
                case 'float':
                    return new NumberField(p.agg_property_id, p.value !== '' ? p.value : 0)
                        .AddSettings({label: alias, placeholder: feature.name})
                        .AddRules({rules: required ? [v => v.length > 0 || requiredMessage] : []});
                case 'string':
                    return new TextField(p.agg_property_id, p.value)
                        .AddSettings({label: alias})
                        .AddRules({rules: required ? [v => v.length > 0 || requiredMessage] : [] });
                case 'bool':
                    return new CheckBox(p.agg_property_id, p.value !== '' ? p.value : false)
                        .AddSettings({label: alias, trueValue: 'true', falseValue: 'false'});
                case 'enum':
                    return new Select(p.agg_property_id,
                        p.value !== '' ? p.value : p.aggregation_level_property.feature.enums[0].value)
                        .AddSettings({label: alias})
                        .AddOptions({options: p.aggregation_level_property.feature.enums, itemText: 'value', itemValue: 'value'})
                        .AddRules({rules: required ? [v => v.length > 0 || requiredMessage] : [] })
                case 'date':
                    return new DatePicker(p.agg_property_id, p.value !== '' ? p.value : new Date().toISOString())
                        .AddSettings({label: alias});
                case 'time':
                    return new TimePicker(p.agg_property_id, p.value !== '' ? p.value : '00:00')
                        .AddSettings({label: alias});
                case 'photo':
                    return new FileInput(p.agg_property_id, p.value !== '' ? [p.value] : [])
                        .AddSettings({label: alias})
                        .AddRules({rules: required ? [v => v.length > 0 || requiredMessage] : [] });
            }
        });
    }

    static CreateFromProjectProperties(projectProperties = []){
        if (!projectProperties) return [];
        return projectProperties.map(p => {
            return this.getAppropriateControl(
                p.feature.type,
                p.id,
                {
                    value: p.value,
                    placeholder: p.alias,
                    label: p.alias,
                    options: p.feature.enums,
                    required: p.required
                }
            )
        });
    }

    static CreateFromSchedulePropertyValue(propertyValue){
        if (!propertyValue)
            return [];
        return [this.getAppropriateControl(
            propertyValue.property.feature.type,
            propertyValue.id,
            {
                value: propertyValue.value,
                placeholder: propertyValue.property.alias,
                label: propertyValue.property.alias,
                options: propertyValue.property.feature.enums,
                required: propertyValue.property.required
            }
        )];
    }

    static CreateFromScheduleProperties(scheduleProperties = [], disabled = false){
        if (!scheduleProperties) return [];
        return scheduleProperties.map(p => {
            return this.getAppropriateControl(
                p.feature.type,
                p.id,
                {
                    value: '', // mock
                    placeholder: p.alias,
                    label: p.alias,
                    options: p.feature.enums,
                    required: p.required,
                    disabled: disabled
                }
            )
        });
    }

    static CreateFromProjectFeatures(properties = []){
        if (!properties) return [];
        let requiredMessage = 'Поле обязательное';
        return properties.map(p => {
            if (!p.value) p.value = '';
            let feature = p.feature;
            switch (p.feature.type) {
                case 'int':
                    return new NumberField(p.feature_id, p.value !== '' ? p.value : 0)
                        .AddSettings({label: feature.name, placeholder: feature.name})
                        .AddRules({rules: feature.required ? [v => String(v).length > 0 || requiredMessage, v => v % 1 === 0 || 'Введите целое число'] : [v => v % 1 === 0 || 'Введите целое число']});
                case 'float':
                    return new NumberField(p.feature_id, p.value !== '' ? p.value : 0)
                        .AddSettings({label: feature.name, placeholder: feature.name})
                        .AddRules({rules: feature.required ? [v => String(v).length > 0 || requiredMessage] : []});
                case 'string':
                    return new TextField(p.feature_id, p.value)
                        .AddSettings({label: feature.name})
                        .AddRules({rules: feature.required ? [v => v.length > 0 || requiredMessage] : [] });
                case 'bool':
                    return new CheckBox(p.feature_id, p.value !== '' ? p.value : '0')
                        .AddSettings({label: feature.name, trueValue: '1', falseValue: '0'})
                        .AddRules({rules: feature.required ? [v => v.length > 0 || requiredMessage] : [] });
                case 'enum':
                    return new Select(p.feature_id,
                        p.value !== '' ? p.value : p.feature.enums[0].value)
                        .AddSettings({label: feature.name})
                        .AddOptions({options: p.feature.enums, itemText: 'value', itemValue: 'value'})
                        .AddRules({rules: feature.required ? [v => v.length > 0 || requiredMessage] : [] });
                case 'date':
                    return new DatePicker(p.feature_id, p.value !== '' ? p.value : new Date().toISOString())
                        .AddSettings({label: feature.name});
                case 'time':
                    return new TimePicker(p.feature_id, p.value !== '' ? p.value : '00:00')
                        .AddSettings({label: feature.name});
                case 'photo':
                    return new FileInput(p.feature_id, p.value !== '' ? [p.value] : [])
                        .AddSettings({label: feature.name})
                        .AddRules({rules: feature.required ? [v => v.length > 0 || requiredMessage] : [] });
            }
        });
    }

    static getAppropriateControl(type, key, {value, label, placeholder, required, options, disabled}) {
        switch (type) {
            case 'int':
                return new NumberField(key, value !== '' ? value : 0)
                    .AddSettings({label: label, placeholder: placeholder, disabled: disabled})
                    .AddRules({rules: required ? Rules.RequiredNumber().concat(Rules.Integer()) : Rules.Integer()});
            case 'float':
                return new NumberField(key, value !== '' ? value : 0)
                    .AddSettings({label: label, placeholder: placeholder, disabled: disabled})
                    .AddRules({rules: required ? Rules.RequiredNumber() : Rules.Anarchy()});
            case 'string':
                return new TextField(key, value)
                    .AddSettings({label: label, disabled: disabled})
                    .AddRules({rules: required ? Rules.Required() : Rules.Anarchy() });
            case 'bool':
                return new CheckBox(key, value !== '' ? value : '0')
                    .AddSettings({label: label, trueValue: '1', falseValue: '0', disabled: disabled})
                    .AddRules({rules: required ? Rules.Required() : Rules.Anarchy() });
            case 'enum':
                return new Select(key, value ? value : options[0].value)
                    .AddSettings({label: label, disabled: disabled})
                    .AddOptions({options: options, itemText: 'value', itemValue: 'value'})
                    .AddRules({rules: required ? Rules.Required() : Rules.Anarchy() });
            case 'date':
                return new DatePicker(key, value !== '' ? value : new Date().toISOString())
                    .AddSettings({label: label, disabled: disabled});
            case 'time':
                return new TimePicker(key, value !== '' ? value : '00:00')
                    .AddSettings({label: label, disabled: disabled});
            case 'photo':
                return new FileInput(key, value !== '' ? [value] : [])
                    .AddSettings({label: label, disabled: disabled})
                    .AddRules({rules: required ? Rules.Required() : Rules.Anarchy() });
        }
    }

    static getControlFromScheduleProperty(property, enabled) {
        let p = {
            key: property.id,
            label: property.alias,
            placeholder: property.placeholder,
            required: property.required,
            type: property.feature.type,
            options: property.feature.enums,
            disabled: !enabled
        };

        switch (p.type) {
            case 'int':
                return new NumberField(p.key)
                    .AddSettings({disabled: p.disabled})
                    .AddRules({rules: p.required ? Rules.RequiredNumber().concat(Rules.Integer()) : Rules.Integer()});
            case 'float':
                return new NumberField(p.key)
                    .AddSettings({disabled: p.disabled})
                    .AddRules({rules: p.required ? Rules.RequiredNumber() : Rules.Anarchy()});
            case 'string':
                return new TextField(p.key)
                    .AddSettings({disabled: p.disabled})
                    .AddRules({rules: p.required ? Rules.Required() : Rules.Anarchy() });
            case 'bool':
                return new CheckBox(p.key)
                    .AddSettings({trueValue: '1', falseValue: '0', disabled: p.disabled})
                    .AddRules({rules: p.required ? Rules.Required() : Rules.Anarchy() });
            case 'enum':
                return new Select(p.key, p.options[0].value)
                    .AddSettings({disabled: p.disabled})
                    .AddOptions({options: p.options, itemText: 'value', itemValue: 'value'})
                    .AddRules({rules: p.required ? Rules.Required() : Rules.Anarchy() });
            case 'date':
                return new DatePicker(p.key)
                    .AddSettings({locale: 'ru', disabled: p.disabled});
            case 'time':
                return new TimePicker(p.key)
                    .AddSettings({disabled: p.disabled});
            case 'photo':
                return new FileInput(p.key)
                    .AddSettings({disabled: p.disabled})
                    .AddRules({rules: p.required ? Rules.Required() : Rules.Anarchy() });
        }
    }
}
