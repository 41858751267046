import groups from "./groups";

const Accesses = () => import(/* webpackChunkName: "admin" */ '../views/admin/accesses/accesses')

export default [{
    path: '/admin/accesses',
    component: Accesses,
    meta: {
        title: 'Редактирование доступов',
        sideBar: {
            label: 'Редактирование доступов',
        },
        group: groups.admin,
        accessKey: 'ROUTE_ADMIN_ACCESSES'
    }
}]
