export default class Rules {
    static Required(message = 'Поле обязательно') {
        return [v => v.length > 0 || message]
    }

    static Anarchy() {
        return []
    }

    static SelectNull(message = 'Поле обязательно') {
        return [v => v !== null || message]
    }

    static RequiredNumber(message = 'Поле обязательно') {
        return [v => String(v).length > 0 || message]
    }

    static EditPass(message = `Не менее ${length} символов`){
        return [v => v === "" || v.length >=6  || message]
    }

    static MinLength(length, message = `Не менее ${length} символов`){
        return [v => v.length >= length || message]
    }

    static MaxLength(length, message = `Не более ${length} символов`){
        return [v => v.length <= length || message]
    }

    static Min(number, message = `Должно быть не менее ${number}`){
        return [v => v >= number || message]
    }

    static Max(number, message = `Должно быть меньше ${number}`){
        return [v => v < number || message]
    }

    static MinMaxEqual(min, max, message = `От ${min} до ${max} включительно`){
        return [v => v <= max && v > min || message]
    }

    static MinMaxLength(min = 0, max = Number.MAX_SAFE_INTEGER, message = `От ${min} до ${max} символов`){
        return [v => v.length >= min && v.length <= max || message]
    }

    static Integer(message = 'Введите целое число'){
        return [v => v % 1 === 0 || message]
    }

    static Login(message = 'Логин должен содержать от 4 до 49 символов a-z, 0-9 и \'.\'') {
        let pattern = /^.{3,49}$/;
        return [v => pattern.test(v) || message]
    }

    static Password() {
        return [
            v => /\d/.test(v) || "Пароль должен содержать цифру",
            v => /[A-Z]/.test(v) || "Пароль должен содержать большую букву",
            v => /[a-z]/.test(v) || "Пароль должен содержать маленькую букву",
            v => /.{6,}/.test(v) || "Пароль должен содержать не менее 6 символов",
            v => !/\s/.test(v) || "Пароль не должен содержать пробелов",
        ]
    }

    static Email(message = 'Некорректный email') {
        let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return [v => pattern.test(v) || message]
    }

    static GPS(message = 'Некорректный GPS') {
        let pattern = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/;
        return [v => pattern.test(v) || message]
    }

    static symbol() {
        return [
            v => v.length > 0 || "Поле обязательно",
            v => !/[;*<>|/\\]/.test(v) || "Не должно быть специальных символов",
        ]
    }
}
