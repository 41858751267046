import {BaseApi} from "../baseApi";
import {ServicePorts} from "../../models/Enums";

export class OutletsApi extends BaseApi{
    constructor() {
        super(ServicePorts.outlets)
    }

    getPropreties(project_id){
        return this.axios.get(`${this.url}/project_properties?project=${project_id}`)
    }

    // address = {address: ""}
    cleanAddress(address) {
        return this.axios.post(`${this.url}/clean_address`, address);
    }

    block(outletId, fd) {
        return this.axios.post(`${this.url}/block/${outletId}`, fd)
    }

    unblock(outletId, fd) {
        return this.axios.post(`${this.url}/unblock/${outletId}`, fd)
    }
    findOrCreateAggregationsTerritory(aggregations_territory) {
        return this.axios.put(`${this.url}/aggregations_territory`, aggregations_territory)
    }
    getAllOutlets(params){
        return this.axios.get(`${this.url}/all?`, {params})
    }

    getOutlet(questId) {
        return this.axios.get(`${this.url}/questionnaire_link/outlets/get/${questId}`)
    }
}
