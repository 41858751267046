<script>
    import DfVTextField from './df-v-text-field';
    import DfVTextarea from './df-v-textarea';
    import DfVSlider from './df-v-slider';
    import DfVSelect from './df-v-select';
    import DfVDatePicker from './df-v-date-picker';
    import DfVCheckbox from './df-v-checkbox';
    import DfVColorPicker from './df-v-color-picker';
    import XNumberField from './x-number-field';
    import DfVFileInput from  './df-v-file-input';
    import DfVAutocomplete from  './df-v-autocomplete';
    import DfVTimePicker from  './df-v-time-picker';
    import UnknownControl from './df-unknown-control';
    import XYandexCoords from './x-yandex-coords';
    import {Control, ControlType} from "../controls";

    export default {
        name: "controls-factory",
        functional: true,
        props: {
            control: Control
        },
        render: function (createElement, context) {
            function getControl() {
                switch (context.props.control.type) {
                    case ControlType.TextField:
                        return DfVTextField;
                    case ControlType.NumberField:
                        return XNumberField;
                    case ControlType.TextArea:
                        return DfVTextarea;
                    case ControlType.Slider:
                        return DfVSlider;
                    case ControlType.Select:
                        return DfVSelect;
                    case ControlType.DatePicker:
                        return DfVDatePicker;
                    case ControlType.CheckBox:
                        return DfVCheckbox;
                    case ControlType.ColorPicker:
                        return DfVColorPicker;
                    case ControlType.FileInput:
                        return DfVFileInput;
                    case ControlType.Autocomplete:
                        return DfVAutocomplete;
                    case ControlType.TimePicker:
                        return DfVTimePicker;
                    case ControlType.CoordinatesPicker:
                        return XYandexCoords;
                    default:
                        return UnknownControl;
                }
            }
            return createElement(getControl(), {
                props: {
                    control: context.props.control
                }
            })
        }
    }
</script>
